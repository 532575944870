//UploadImage

// import ApiConfig from "src/apiconfig/apiconfig";
// import axios from "axios";
// import toast from "react-hot-toast";

// import React, { createContext, useState, useContext } from "react";

// const UploadImage = createContext();

// const UploadImageContext = ({ children }) => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [image, setImage] = useState("");

//   const uploadImage = async (file, fieldName) => {
//     console.log("dataaaa ===> ", file, fieldName);
//     const formData = new FormData();
//     formData.append("image", file);
//     setIsLoading(true);
//     try {
//       const res = await axios({
//         method: "POST",
//         url: ApiConfig.uploadFiles,
//         data: formData,
//       });
//       setImage(res?.data?.result);
//       console.log("response ", res?.data?.result);
//       toast.success(res?.data?.responseMessage);
//     } catch (error) {
//       console.log("error ", error);
//     }
//     setIsLoading(false);
//   };

//   const values = { isLoading, setIsLoading, image, setImage, uploadImage };

//   return <UploadImage.Provider value={values}>{children}</UploadImage.Provider>;
// };

// export default UploadImageContext;
// export const useUploadImage = () => useContext(UploadImage);
// //prajkta he bg voice ch context

//UploadImage

import ApiConfig from "src/apiconfig/apiconfig";
import axios from "axios";
import toast from "react-hot-toast";

import React, { createContext, useState, useContext } from "react";

const UploadImage = createContext();

const UploadImageContext = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState("");
  const [file, setFile] = useState("");

  const uploadImage = async (file, fieldName) => {
    console.log("dataaaa ===> ", file, fieldName);
    const formData = new FormData();
    formData.append("image", file);
    setIsLoading(true);
    try {
      const res = await axios({
        method: "POST",
        // url: ApiConfig.uploadFiles,
        url: ApiConfig.uploadFile,
        data: formData,
      });
      setImage(res?.data?.result);
      console.log("response ", res?.data?.result);
      // toast.success(res?.data?.responseMessage);
      // toast.success("Image Uploaded");
    } catch (error) {
      console.log("error ", error);
    }
    setIsLoading(false);
  };

  const uploadFile = async (file) => {
    console.log("dataaaa ===> ", file);
    const formData = new FormData();
    formData.append("image", file);
    setIsLoading(true);
    try {
      const res = await axios({
        method: "POST",
        url: ApiConfig.uploadFile,
        data: formData,
      });
      setFile(res?.data?.result);
      console.log("response file", res?.data?.result);
      // toast.success(res?.data?.responseMessage);
      // toast.success("Audio Uploaded");
    } catch (error) {
      console.log("error ", error);
    }
    setIsLoading(false);
  };

  const values = {
    isLoading,
    setIsLoading,
    image,
    setImage,
    uploadImage,
    uploadFile,
    setFile,
    file,
  };

  return <UploadImage.Provider value={values}>{children}</UploadImage.Provider>;
};

export default UploadImageContext;
export const useUploadImage = () => useContext(UploadImage);
