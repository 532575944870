import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";
import DashboardLayout from "src/layouts/DashboardLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Login/Login")),
  },
  {
    exact: true,
    path: "/forgot-password",
    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/pages/Auth/ForgotPassword/ForgotPassword")
    ),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/pages/Auth/ResetPassword/ResetPassword")
    ),
  },

  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/VerifyOTP/VerifyOTP")),
  },

  {
    exact: true,
    path: "/dashboard",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Index")),
  },
  {
    exact: true,
    path: "/advertisement-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdvertisementManagement/AdvertisementManagement")
    ),
  },
  {
    exact: true,
    path: "/user-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/UserManagement/Index")),
  },
  {
    exact: true,
    path: "/set-commission",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/UserManagement/SetCommission")
    ),
  },
  {
    exact: true,
    path: "/user-view",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/UserManagement/UserView")),
  },
  {
    exact: true,
    path: "/assignwork-view",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/UserManagement/AssignWorkView")),
  },
  {
    exact: true,

    path: "/create-job-view",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/UserManagement/CreateJobView")),
  },
  {
    exact: true,
    path: "/assignproject",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/UserManagement/AssignedProject")),
  },
  {
    exact: true,
    path: "/create-job",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/UserManagement/CreateJobs")),
  },
  
  {
    exact: true,
    path: "/new-request-listing",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/MediaManagement/MediaListing/NewRequestList")
    ),
  },
  {
    exact: true,
    path: "/new-request-view",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/MediaManagement/MediaListing/NewRequestView")
    ),
  },
  {
    exact: true,
    path: "/edit-profile",
    layout: DashboardLayout,
    guard: true,

    component: lazy(() => import("src/views/pages/Profile/EditProfile")),
  },
  {
    exact: true,
    path: "/view-profile",
    layout: DashboardLayout,
    guard: true,

    component: lazy(() => import("src/views/pages/Profile/ViewProfile")),
  },
  {
    exact: true,
    path: "/change-password",
    layout: DashboardLayout,
    guard: true,

    component: lazy(() => import("src/views/pages/Profile/ChangePassword")),
  },
  {
    exact: true,
    path: "/media-listing",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() =>
      import("src/views/pages/MediaManagement/MediaListing/MediaListing")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/admin-chat",
    layout: DashboardLayout,

    component: lazy(() => import("src/views/pages/Chat/Chat")),
  },

  // {
  //   exact: true,
  //   path: "/admin-chat",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/errors/Chat/chatHistory")),
  // },
  {
    exact: true,
    path: "/completed-job-list",
    layout: DashboardLayout,
    guard: true,

    component: lazy(() =>
      import("src/views/pages/JobManagement/CompletedJob/CompletedJobList")
    ),
  },
  {
    exact: true,
    path: "/user-adv-view",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() =>
      import(
        "src/views/pages/UserManagement/UserTypesView/Advertisement/AdvertisementView"
      )
    ),
  },
  
  {
    exact: true,
    path: "/Skills-list",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() =>
      import(
        "src/views/pages/JobManagement/SkillsManagement/SkillsList"
      )
    ),
  },
  {
    exact: true,
    path: "/Add-Skills",
    layout: DashboardLayout,
    guard: true,

    component: lazy(() =>
      import("src/views/pages/JobManagement/SkillsManagement/AddSkill")
    ),
  },
  {
    exact: true,
    path: "/service-category-list",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() =>
      import(
        "src/views/pages/JobManagement/ServiceCategory/ServiceCategoryList"
      )
    ),
  },
  {
    exact: true,
    path: "/add-service-category",
    layout: DashboardLayout,
    guard: true,

    component: lazy(() =>
      import("src/views/pages/JobManagement/ServiceCategory/AddServiceCategory")
    ),
  },
  {
    exact: true,
    path: "/edit-service-category",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/JobManagement/ServiceCategory/EditCategoryList")
    ),
  },
  {
    exact: true,
    path: "/edit-skill",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/JobManagement/SkillsManagement/EditSkill")
    ),
  },
  {
    exact: true,
    path: "/view-skill",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/JobManagement/SkillsManagement/ViewSkill")
    ),
  },
  {
    exact: true,
    path: "/view-service-category",
    layout: DashboardLayout,
    guard: true,

    component: lazy(() =>
      import(
        "src/views/pages/JobManagement/ServiceCategory/ViewServiceCategory"
      )
    ),
  },

  {
    exact: true,
    path: "/add-category",
    layout: DashboardLayout,
    guard: true,

    component: lazy(() =>
      import("src/views/pages/MediaManagement/Category/AddCategory")
    ),
  },
  {
    exact: true,
    path: "/edit-category",
    guard: true,

    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/MediaManagement/Category/EditCategory")
    ),
  },
  {
    exact: true,
    path: "/add-theme",
    guard: true,

    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/MediaManagement/Theme/AddTheme")
    ),
  },
  {
    exact: true,
    path: "/edit-theme",
    guard: true,

    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/MediaManagement/Theme/EditTheme")
    ),
  },
  {
    exact: true,
    path: "/view-transaction-detail",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/TransactionManagement/ViewTransactionDetail")
    ),
  },
  {
    exact: true,
    path: "/dispute-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/DisputeManagement/Index")),
  },
  {
    exact: true,
    path: "/view-dispute-detail",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/DisputeManagement/ViewDispute")
    ),
  },
  {
    exact: true,
    path: "/add-topic",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/MediaManagement/Topic/AddTopic")
    ),
  },
  {
    exact: true,
    path: "/edit-topic",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/MediaManagement/Topic/EditTopic")
    ),
  },
  {
    exact: true,
    path: "/category-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/MediaManagement/Category/CategoryList")
    ),
  },
  {
    exact: true,
    path: "/theme-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/MediaManagement/Theme/ThemeListing")
    ),
  },
  {
    exact: true,
    path: "/topic-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/MediaManagement/Topic/TopicListing")
    ),
  },
  {
    exact: true,
    path: "/transaction-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/TransactionManagement/Index")
    ),
  },
  {
    exact: true,
    path: "/view-media",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/UserManagement/UserTypesView/MediaList/ViewMedia")
    ),
  },
  {
    exact: true,
    path: "/advertisement-request-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdvertisementManagement/RequestAdsList")
    ),
  },
  {
    exact: true,
    path: "/advertisement-req-details",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdvertisementManagement/ViewAdvDetails")
    ),
  },
  {
    exact: true,
    path: "/ussd-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/USSDManagement/USSDManagement")
    ),
  },
  {
    exact: true,
    path: "/advertisement-details",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdvertisementManagement/ViewAdvDetails")
    ),
  },
  {
    exact: true,
    path: "/sub-admin-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/SubAdminManagement/SubAdminList")
    ),
  },
  {
    exact: true,
    path: "/edit-admin-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/SubAdminManagement/EditSubAdmin/EditSubAdmin")
    ),
  },
  {
    exact: true,
    path: "/view-sub-admin",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/SubAdminManagement/ViewSubAdmin/ViewSubAdmin")
    ),
  },
  {
    exact: true,
    path: "/add-sub-admin",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/SubAdminManagement/AddSubAdmin/AddSubAdmin")
    ),
  },
  {
    exact: true,
    path: "/update-sub-admin",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/SubAdminManagement/AddSubAdmin/AddSubAdmin")
    ),
  },
  {
    exact: true,
    path: "/query-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/QueryManagement/index")),
  },
  {
    exact: true,
    path: "/query-view",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/QueryManagement/QueryView")),
  },
  {
    exact: true,
    path: "/job-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/JobManagement/Job/JobList")),
  },
  {
    exact: true,
    path: "/view-job-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/JobManagement/Job/ViewJobList")
    ),
  },
  {
    exact: true,
    path: "/view-completed-job",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/JobManagement/CompletedJob/ViewCompletedJob")
    ),
  },

  {
    exact: true,
    path: "/add-job",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/JobManagement/Job/AddJob")
    ),
  },
  {
    exact: true,
    path: "/job-request-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/JobManagement/Job/JobRequestDetail")
    ),
  },
  {
    exact: true,
    path: "/static-content-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/StaticContentManagement/StaticContentListing/StaticContentListing"
      )
    ),
  },

  {
    exact: true,
    path: "/static-content",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/StaticContentManagement/TermsAndConditions/TermsAndConditions"
      )
    ),
  },
  {
    exact: true,
    path: "/edit-static-content",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/StaticContentManagement/TermsAndConditions/EditTermsAndConditions"
      )
    ),
  },
  {
    exact: true,
    path: "/edit-about-us",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContentManagement/AboutUs/EditAboutUs")
    ),
  },

  {
    exact: true,
    path: "/about-us",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContentManagement/AboutUs/AboutUs")
    ),
  },

  {
    exact: true,
    path: "/banner-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/StaticContentManagement/BannerManagment/BannerManagement"
      )
    ),
  },
  {
    exact: true,
    path: "/add-banner-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/StaticContentManagement/BannerManagment/AddBanner"
      )
    ),
  },
  {
    exact: true,
    path: "/edit-banner-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/StaticContentManagement/BannerManagment/EditBanner"
      )
    ),
  },
  {
    exact: true,
    path: "/team-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/StaticContentManagement/TeamManagment/TeamManagement"
      )
    ),
  },
  {
    exact: true,
    path: "/edit-team",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContentManagement/TeamManagment/EditTeam")
    ),
  },
  {
    exact: true,
    path: "/view-team",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContentManagement/TeamManagment/ViewTeam")
    ),
  },
  {
    exact: true,
    path: "/demo-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContentManagement/Demo/Demo")
    ),
  },
  {
    exact: true,
    path: "/view-demo-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContentManagement/Demo/ViewDemo")
    ),
  },
  {
    exact: true,
    path: "/edit-demo",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContentManagement/Demo/EditDemo")
    ),
  },
  {
    exact: true,
    path: "/add-static-content",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/StaticContentManagement/StaticContentListing/AddStaticContent"
      )
    ),
  },

  {
    exact: true,
    path: "/subscription-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/SuscriptionManagement/index")
    ),
  },
  {
    exact: true,
    path: "/view-plan-details",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/SuscriptionManagement/ViewPlanDetails")
    ),
  },
  {
    exact: true,
    path: "/view-user-details",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/SuscriptionManagement/UsersDetails")
    ),
  },
  {
    exact: true,
    path: "/view-advertisement-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/ContractManagement/Advertisement/ViewAdvertisement"
      )
    ),
  },
  {
    exact: true,
    path: "/contract-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ContractManagement/Index")),
  },
  {
    exact: true,
    path: "/view-media-details",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/ContractManagement/Podcast/ViewMediaDetails")
    ),
  },
  {
    exact: true,
    path: "/project-managment",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ProjectManagment/Index")),
  },
  {
    exact: true,
    path: "/referral-managment",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ReferralManagement/Index")),
  },
  // {
  //   exact: true,
  //   path: "/commission-managment",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/CommisionManagement/Index")),
  // },
  {
    exact: true,
    path: "/job-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/ContractManagement/Job/JobContractlist")
    ),
  },
  {
    exact: true,
    path: "/view-job-details",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/ContractManagement/Job/ViewJobDetails")
    ),
  },
  {
    exact: true,
    path: "/commission-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/CommissionManagement/Index")),
  },
  {
    exact: true,
    path: "/view-commission-history",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/CommissionManagement/CommisionDetail")),
  },
  {
    exact: true,
    path: "/wallet-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/WalletManagement/WalletManagement")),
  },
  {
    exact: true,
    path: "/notification-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/NotificationManagement/Index")),
  },
  {
    exact: true,
    path: "/claim-request",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/WalletManagement/ClaimRequestViewDetails")),
  },
  {
    exact: true,
    path: "/new-project-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/ProjectManagment/NewProjectRequest/NewProjectList"
      )
    ),
  },
  {
    exact: true,
    path: "/view-project-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/ProjectManagment/NewProjectRequest/ViewProjectList"
      )
    ),
  },
  {
    exact: true,
    path: "/view-project",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/ContractManagement/Project/viewProject"
      )
    ),
  },
  {
    exact: true,
    path: "/edit-faq",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContentManagement/FAQ/EditFAQ")
    ),
  },
  {
    exact: true,
    path: "/how-to-use",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContentManagement/HowtoUse/HowToUse")
    ),
  },
  {
    exact: true,
    path: "/edit-how-to-use",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContentManagement/HowtoUse/EditHowToUse")
    ),
  },
  {
    exact: true,
    path: "/referral-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ReferralManagement/Index")),
  },
  {
    exact: true,
    path: "/manage-reward-token",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/ReferralManagement/ManageRewardToken")
    ),
  },
  {
    exact: true,
    path: "/webvideo",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/webvideo")),
  },
  {
    exact: true,
    path: "/webvoice",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/VideoCall")),
  },
  {
    exact: true,
    path: "/webvoice-test",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/webaudio")),
  },
  {
    exact: true,
    path: "/Dispute-Management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/DisputeManagement/Index")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },

  {
    component: () => <Redirect to="/404" />,
  },
];
