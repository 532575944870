import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import ApiConfig from "src/apiconfig/apiconfig";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("creatturAccessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("creatturAccessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("creatturAccessToken");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [timerActive, setTimerActive] = useState(false);
  const [remainingTime, setRemainingTime] = useState(180);
  const [expiredOTP, setExpiredOTP] = useState(false);
  const [otpPage, setOtpPage] = useState(false)
  const history = useHistory()


  useEffect(() => {
    const storedRemainingTime = localStorage.getItem("remainingTime");
    if (storedRemainingTime !== null) {
      setRemainingTime(parseInt(storedRemainingTime, 10));
    }

    if (storedRemainingTime > 0) {
      setTimerActive(true);
    }

    if (timerActive && remainingTime > 0) {
      const timerInterval = setInterval(() => {
        setRemainingTime((prevTime) => {
          const newTime = prevTime - 1;

          localStorage.setItem("remainingTime", newTime.toString());

          return newTime;
        });
      }, 1000);

      return () => clearInterval(timerInterval);
    } else if (remainingTime === 0) {
      setTimerActive(false);
      setExpiredOTP(true);
      localStorage.removeItem("remainingTime");
    } else if (remainingTime) {
    }
  }, [remainingTime, timerActive]);

  const getAdminProfile = async () => {
    setIsLoading(true);
    try {
      const res = await axios({
        method: "GET",
        url: ApiConfig.getAdminProfile,
        headers: {
          token: localStorage.getItem("adminToken"),
        },
      });
      if (res?.data?.responseCode === 200) {

        setUserData(res?.data?.result?.data);
      }

      //   } catch (error) {
      //     if (error?.response) {
      //       toast.error(error?.response?.data?.responseMessage);
      //     }
      //     if (error?.response?.data?.responseCode === 401) {
      //       history.push('/')
      //     }
      //   } finally {
      //     setIsLoading(true);
      //   }
      // };
    } catch (error) {
      if (
        error?.response?.data?.responseCode === 500 ||
        error?.response?.data?.responseMessage === "jwt malformed"
      ) {
        toast.error("Session Expired!");
        localStorage.removeItem("adminToken");
        setIsLogin(false);
        history.push("/");
        // window.location.href = "/";
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("adminToken")) {
      getAdminProfile();
    }
  }, [localStorage.getItem("adminToken")]);

  let data = {
    userLoggedIn: isLogin,
    userData,
    setTimerActive,
    expiredOTP,
    getAdminProfile: getAdminProfile,
    timerActive,
    setTimerActive,
    setRemainingTime,
    remainingTime,
    setIsLogin,
    setOtpPage,
    otpPage,
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
    checkLogin: (token) => {
      checkLogin(token);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
