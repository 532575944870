import React from "react";
import { makeStyles, Box, Container, Grid } from "@material-ui/core";
import Logo from "./../../component/Logo";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  loginLayoutBox: {
    backgroundImage: "url(images/Login_layout_back.png)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.8)", // Adjusted background color to make it darker

    "& .rightGridLoginLayout": {
      background: "rgba(255,255,255,0.90)",
    },

    "& .loginContentLayoutBox": {
      height: "100%",
      width: "100%",
      maxWidth: "1100px",
      minHeight: "655px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "500px",
      },
      "& .loginHeadphoneBox": {
        "& img": {
          width: "100%",
          height: "100%",
        },
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
    },
    "& .footer": {
      display: "flex",
      position: "fixed",
      bottom: "0px",
      justifyContent: "center",
      background: "#636f83",
      height: "30px",
      width: "100%",
      alignItems: "center",
      color: "#fff",
      [theme.breakpoints.down("xs")]: {
        fontSize: "13px",
      },
    },
  },
}));

export default function LoginLayout({ children }) {
  const history = useHistory();
  const classes = useStyles();

  return (
    <>
      <Box className={classes.loginLayoutBox}>
        <Box className="loginContentLayoutBox displayCenter">
          <Grid container style={{ display: "flex", justifyContent: "center" }}>
            <Grid item xs={12} sm={12} md={6} className="rightGridLoginLayout">
              <Box className="childrenBox">{children}</Box>
            </Grid>
          </Grid>
        </Box>
        <div className="footer">© 2024 Fululizo Media | Integral Media Limited</div>
      </Box>
    </>
  );
}
LoginLayout.propTypes = {
  children: PropTypes.node,
};
