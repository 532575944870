import { combineReducers } from "redux";
import messagesReducer from "./messages";
import searchUserListReducer from "./searchUserList";
import webTokenReducer from "./setToken";

const rootReducer = combineReducers({
  messagesReducer,
  searchUserListReducer,
  webTokenReducer,
});

export default rootReducer;
