// import { initializeApp } from "firebase/app";
// import {
//   getMessaging,
//   getToken,
//   onMessage,
//   isSupported,
// } from "firebase/messaging";

// // Firebase configuration object with environment variables
// var firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDERID,
//   appId: process.env.REACT_APP_FIREBASE_APPID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
// };
// // var firebaseConfig = {
// //   apiKey: "AIzaSyCxolpWth9Poxhsw3gk1Rxf8BTHRpWUoFw",
// //   authDomain: "javatonode.firebaseapp.com",
// //   projectId: "javatonode",
// //   storageBucket: "javatonode.appspot.com",
// //   messagingSenderId: "1019698100663",
// //   appId: "1:1019698100663:web:0b9f44ded82f0df69dd032",
// //   measurementId: "G-C4N37R2KPN",
// // };

// // Initialize Firebase app with the configuration
// const firebaseApp = initializeApp(firebaseConfig);

// // Initialize Firebase messaging
// const messaging = (async () => {
//   try {
//     const isSupportedBrowser = await isSupported();
//     if (isSupportedBrowser) {
//       return getMessaging(firebaseApp);
//     }
//     console.log("Firebase not supported in this browser");
//     return null;
//   } catch (err) {
//     console.log(err);
//     return null;
//   }
// })();

// // Function to fetch the messaging token
// export const fetchToken = async (setTokenFound, setNotificationErr) => {
//   try {
//     const messagingResolve = await messaging;
//     const currentToken = await getToken(messagingResolve, {
//       vapidKey: process.env.REACT_APP_VAPID_KEY,
//     });
//     if (currentToken) {
//       setTokenFound(true);
//       return currentToken;
//     } else {
//       console.log(
//         "No registration token available. Request permission to generate one."
//       );
//       setTokenFound(false);
//       setNotificationErr(true);
//     }
//   } catch (err) {
//     console.log("An error occurred while retrieving token. ", err);
//     setNotificationErr(true);
//   }
// };

// // Function to listen for incoming messages
// export const onMessageListener = async () =>
//   new Promise((resolve) =>
//     (async () => {
//       const messagingResolve = await messaging;
//       onMessage(messagingResolve, (payload) => {
//         console.log("🚀 ~ onMessage ~ payload:", payload);
//         resolve(payload);
//       });
//     })()
//   );

// firebase.js;

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Replace this firebaseConfig object with the congurations for the project you created on your firebase console.
var firebaseConfig = {
  apiKey: "AIzaSyCxolpWth9Poxhsw3gk1Rxf8BTHRpWUoFw",
  authDomain: "javatonode.firebaseapp.com",
  projectId: "javatonode",
  storageBucket: "javatonode.appspot.com",
  messagingSenderId: "1019698100663",
  appId: "1:1019698100663:web:1c9852ed97cfa2139dd032",
  measurementId: "G-TL7ZCPKBVN",
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      "BFxQw9iXd2rDbLXSizHbAdQsZleyf7FDGLI45ibU9MOHih1k6Q5yS9JjSFKC0Zu2bRKQ0icAGlw4YrN1s20iPAk",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

//......

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });
