// import React, { useEffect, useState, useContext } from "react";
// import { useLocation, matchPath, useHistory } from "react-router-dom";
// import PerfectScrollbar from "react-perfect-scrollbar";
// import PropTypes from "prop-types";
// import {
//   Box,
//   Drawer,
//   Hidden,
//   List,
//   Button,
//   ListSubheader,
//   makeStyles,
//   Typography,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   Slide,
//   Paper,
//   IconButton,
//   ListItem,
//   DialogTitle,
//   Avatar,
//   useMediaQuery,
//   // handleClose,
// } from "@material-ui/core";
// import NavItem from "./NavItem";
// import { AiOutlineLogout } from "react-icons/ai";
// import { AuthContext } from "src/context/Auth";
// import { BiLogOut } from "react-icons/bi";
// import { MdOutlineDashboard } from "react-icons/md";
// import { TbLogout } from "react-icons/tb";
// import { RiAdvertisementFill } from "react-icons/ri";
// import { RiCoupon2Fill } from "react-icons/ri";
// import { MdAdminPanelSettings } from "react-icons/md";
// import { FaUserTie } from "react-icons/fa";
// import { GrMultimedia } from "react-icons/gr";
// import { GrTasks } from "react-icons/gr";
// import { AiFillProject } from "react-icons/ai";
// import { FaFileContract } from "react-icons/fa6";
// import { TbTransfer } from "react-icons/tb";
// import { MdOutlineQueryStats } from "react-icons/md";
// import { MdSubscriptions } from "react-icons/md";
// import { FaMoneyBill } from "react-icons/fa6";
// import { FaWallet } from "react-icons/fa6";
// import { FaRegBell } from "react-icons/fa6";
// import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
// import { CiChat1 } from "react-icons/ci";
// import { CgProfile } from "react-icons/cg";
// import { TbNotes } from "react-icons/tb";
// import { FaNetworkWired } from "react-icons/fa6";
// import CloseIcon from "@material-ui/icons/Close";
// const sections = [
//   {
//     items: [
//       {
//         title: "Dashboard",
//         modules: "dashboard",
//         icon: MdOutlineDashboard,
//         href: "/dashboard",
//       },
//       {
//         title: "User Management",
//         modules: "subAdmins",
//         icon: FaUserTie,
//         href: "/user-management",
//       },
//       {
//         title: "Media Management",
//         modules: "dashboard",
//         icon: GrMultimedia,
//         items: [
//           {
//             title: "Category",
//             href: "/category-list",
//           },
//           {
//             title: "Theme",
//             href: "/theme-list",
//           },
//           {
//             title: "Topic",
//             href: "/topic-list",
//           },
//           {
//             title: "Media Listing",
//             href: "/media-listing",
//           },
//         ],
//       },
//       {
//         title: "Job Management",
//         modules: "dashboard",
//         icon: GrTasks,
//         items: [
//           // {
//           //   title: "Expertise Management",
//           //   href: "/service-category-list",
//           // },
//           {
//             title: "Skills Management",
//             href: "/Skills-list",
//           },
//           {
//             title: "Service Category",
//             href: "/service-category-list",
//           },
//           {
//             title: "Job List",
//             href: "/job-list",
//           },
//           {
//             title: "Completed Job",
//             href: "/completed-job-list",
//           },
//         ],
//       },
//       {
//         title: "Advertisement Management",
//         modules: "dashboard",
//         icon: RiAdvertisementFill,
//         href: "/advertisement-management",
//       },
//       {
//         title: "Project Management",
//         modules: "dashboard",
//         icon: AiFillProject,
//         href: "/project-managment",
//       },
//       {
//         title: "Contract Management",
//         modules: "dashboard",
//         icon: FaFileContract,
//         href: "/contract-management",
//       },
//       {
//         title: "Dispute Management",
//         modules: "dashboard",
//         icon: FaMoneyBill,
//         href: "/dispute-management",
//       },

//       {
//         title: "Transaction Management",
//         modules: "dashboard",
//         icon: TbTransfer,
//         href: "/transaction-management",
//       },
//       {
//         title: "Query Management",
//         modules: "dashboard",
//         icon: MdOutlineQueryStats,
//         href: "/query-management",
//       },
//       {
//         title: "Subscription Management",
//         modules: "dashboard",
//         icon: MdSubscriptions,
//         href: "/subscription-list",
//       },
//       {
//         title: "USSD Management",
//         modules: "dashboard",
//         icon: RiCoupon2Fill,
//         href: "/ussd-management",
//       },
//       {
//         title: "Commision Management",
//         modules: "dashboard",
//         icon: FaMoneyBill,
//         href: "/commission-management",
//       },
//       {
//         title: "Wallet Management",
//         modules: "dashboard",
//         icon: FaWallet,
//         href: "/wallet-management",
//       },
     
//       {
//         title: "Referral Management",
//         modules: "dashboard",
//         icon: FaNetworkWired,
//         href: "/referral-management",
//       },
//       {
//         title: "Sub Admin Management",
//         modules: "dashboard",
//         icon: SupervisorAccountIcon,
//         href: "/sub-admin-management",
//       },
//       {
//         title: "Static Content Management",
//         modules: "dashboard",
//         icon: TbNotes,
//         href: "/static-content-list",
//       },
//       {
//         title: "My Account",
//         modules: "dashboard",
//         icon: CgProfile,
//         href: "/view-profile",
//       },
//       {
//         title: "Notification",
//         modules: "dashboard",
//         icon: FaRegBell,
//         href: "/notification-management",
//       },
//       {
//         title: "Chat",
//         modules: "dashboard",
//         icon: CiChat1,
//         href: "/admin-chat",
//       },
//     ],
//   },
// ];
// const sections2 = [
//   {
//     items: [
//       {
//         title: "LOGOUT",
//         modules: "Logout",
//         icon: TbLogout,
//         href: "logout",
//       },
//     ],
//   },
// ];

// function renderNavItems({
//   items,
//   pathname,
//   depth = 0,
//   handleLogoutClick,
//   setIsLogout,
// }) {
//   return (
//     <List disablePadding>
//       {console.log(handleLogoutClick, "items==>")}
//       {/* {console.log(pathname, "items==>")}
//       {console.log(depth, "items==>")} */}
//       {items.reduce(
//         (acc, item) =>
//           reduceChildRoutes({ acc, item, pathname, depth, setIsLogout }),
//         []
//       )}
//     </List>
//   );
// }

// function reduceChildRoutes({ acc, pathname, item, depth, setIsLogout }) {
//   const key = item.title + depth;
//   const match = (item.items || []).some((child) =>
//     matchPath(pathname, {
//       path: child.href,
//       exact: true,
//     })
//   );

//   if (item.items) {
//     const open =
//       matchPath(pathname, {
//         path: item.href,
//         exact: false,
//       }) || match;
//     acc.push(
//       <NavItem
//         depth={depth}
//         icon={item.icon}
//         info={item.info}
//         key={key}
//         open={item.title === "LOGOUT" ? setIsLogout(true) : Boolean(open)}
//         title={item.title}
//       >
//         {renderNavItems({
//           depth: depth + 1,
//           pathname,
//           items: item.items,
//         })}
//       </NavItem>
//     );
//   } else {
//     acc.push(
//       <NavItem
//         depth={depth}
//         href={item.href}
//         icon={item.icon}
//         info={item.info}
//         key={key}
//         title={item.title}
//       />
//     );
//   }
//   return acc;
// }
// const useStyles = makeStyles((theme) => ({
//   mobileDrawer: {
//     width: 256,
//     background: "#fff",
//   },
//   adminName: {
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "center",
//     marginTop: "7px",

//     "& h6": {
//       marginTop: "7px",
//     },
//     "& .MuiAvatar-root": {
//       width: "75px",
//       height: "75px",
//     },
//   },
//   sectionsHeading: {
//     padding: "2px 0px 0px 22px",
//     lineHeight: "normal",
//     color: "#7C7C7C",
//     fontSize: "12px",
//   },
//   desktopDrawer: {
//     // boxShadow: "1px 0px 5px 0px rgba(0,0,0,0.75)",
//     boxShadow: "10px 0 10px rgba(0, 0, 0, 0.1)",
//     top: "76px",
//     width: "300px",
//     height: "88vh",
//     marginTop: "6px",
//     background: "#fff",
//   },
//   avatar: {
//     cursor: "pointer",
//     width: 64,
//     height: 64,
//   },
//   socialIcon: {
//     cursor: "pointer",
//     marginRight: 5,
//   },
//   button: {
//     width: "100%",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "left",
//     height: "45px",
//     paddingLeft: "17px",
//     borderRadius: "12px",
//     marginTop: "-30px",
//     "&:hover": {
//       color: "#F5C843",
//     },
//     "& svg": {
//       color: "#F5C843",
//       fontSize: "20px",
//     },
//   },

//   btnBox: {
//     position: "relative",
//     left: "30%",
//     bottom: "-250px",
//   },
//   logoutButton: {
//     display: "flex",
//     justifyContent: "start",
//     alignItems: "center",
//     position: "absolute",
//     bottom: "19px",
//     left: "17px",
//     background: "transparent",
//     fontWeight: "400",
//     fontSize: "13px",
//     color: "#EC1F24",
//   },
//   sideMenuBox: {
//     "& .MuiCollapse-wrapperInner": {
//       marginLeft: "45px",
//     },
//   },
//   dailogOpen: {
//     "& .MuiDialog-paperWidthSm": {
//       width: "652px !important",
//     },
//     "& .MuiDialogContent-root": {
//       padding: "8px 39px",
//     },
//     "& .MuiDialogTitle-root ": {
//       background: "#767676 !important",
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       padding: "0px ",
//       "& .MuiTypography-h6": {
//         width: "100%",
//       },
//     },
//     "& .titleBox": { flex: "1", textAlign: "center", padding: "18px 0px" },

//     "& .icon": {
//       color: "#fff",
//       alignItems: "center",
//       paddingRight: "25px",
//       position: "absolute",
//       top: "0px",
//       right: "0px",
//     },
//     "& .MuiButton-text": {
//       color: "#ED2124",
//     },
//     "& .MuiDialogActions-root": {
//       justifyContent: "center",
//     },
//     "& .MuiOutlinedInput-root": {
//       height: "auto",
//     },
//   },
// }));

// const NavBar = ({ onMobileClose, openMobile, handleClose }) => {
//   const classes = useStyles();
//   const auth = useContext(AuthContext);
//   const location = useLocation();
//   const isSmallScreen = useMediaQuery("(max-width:600px)");
//   let permission = auth?.userData?.subAdminPermission;
//   console.log(auth, "permissionpermission");
//   // let connectedExchange = auth.userData?.connectedExchange?.length;
//   const history = useHistory();
//   const [isLogout, setIsLogout] = useState(false);
//   const handleLogoutClick = () => {
//     console.log("log");
//     setIsLogout(true);
//   };
//   useEffect(() => {
//     if (openMobile && onMobileClose) {
//       onMobileClose();
//     }
//   }, [location.pathname]); // eslint-disable-line
//   const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
//   });

//   //  const filteredSections = sections.filter((section) => {
//   //    return permission?.[section.title.toLowerCase()] === true;
//   //  });
//   //  console.log(filteredSections, "filteredSections");

//   let fname = auth?.userData?.firstName?.length;
//   let fname1 = auth?.userData?.firstName ?? "";
//   let lname = auth?.userData?.lastName?.length;
//   let lname1 = auth?.userData?.lastName ?? "";

//   let fname2 = fname1;
//   let lname2 = lname1;
//   if (fname > 5) {
//     fname2 = fname1.slice(0, 5) + "...";
//     console.log(fname2 + "...");
//   }
//   if (lname > 5) {
//     lname2 = lname1.slice(0, 5) + "...";
//     console.log(lname2 + "...");
//   }
//   const content = (
//     <Box height="100%" display="flex" flexDirection="column">
//       <PerfectScrollbar options={{ suppressScrollX: true }}>
//         <Box className={classes.adminName}>
//           <Avatar src={auth?.userData?.profilePic ?? ""} alt="" />
//           <Box style={{ display: "flex", justifyContent: "center" }}>
//             <Typography variant="h6">
//               {/* {
//               // let name= auth?.userData?.firstName.length
//             } */}
//            {/* {fname1.slice(0, 10)} {lname1.slice(0, 10)} */}
//            {fname1.length > 10 ? `${fname1.slice(0, 10)}...` : fname1} {lname1.length > 10 ? `${lname1.slice(0, 10)}...` : lname1}
//               {/* {auth?.userData?.firstName ?? ""} {auth?.userData?.lastName ?? ""} */}
//             </Typography>
//           </Box>
//         </Box>
//         <Box pt={2} pb={2}>
//           <Box
//             className="sideMenuBox"
//             style={{
//               boxShadow: "0px 23.306px 58.264px 0px rgba(220, 224, 249, 0.50)",
//             }}
//           >
//             {[
//               { sections: sections, heading: null },
//               { sections: sections2, heading: null },
//             ]?.map((category, categoryIndex) => (
//               <React.Fragment key={`category${categoryIndex}`}>
//                 <Typography className={classes.sectionsHeading}>
//                   {category.heading}
//                 </Typography>
//                 {categoryIndex === 1 ? (
//                   <List
//                     subheader={
//                       <ListSubheader disableGutters disableSticky>
//                         {category.sections[0]?.subheader}
//                       </ListSubheader>
//                     }
//                   >
//                     {category.sections[0]?.items.map((data, j) => (
//                       <ListItem
//                         button
//                         style={{
//                           display: "flex",
//                           alignItems: "center",
//                           gap: "25px",
//                           marginLeft: isSmallScreen ? "5px" : "36px",
//                         }}
//                         key={`item${j}`}
//                         selected={location.pathname === data.href}
//                         onClick={() => {
//                           data.title !== "LOGOUT"
//                             ? history.push(data.href)
//                             : handleLogoutClick();
//                         }}
//                       >
//                         <BiLogOut />
//                         <span>{data.title}</span>
//                       </ListItem>
//                     ))}
//                   </List>
//                 ) : (
//                   renderNavItems({
//                     img: category.sections[0]?.img,
//                     items: category.sections[0]?.items || [],
//                     pathname: location.pathname,
//                     handleLogoutClick,
//                   })
//                 )}
//               </React.Fragment>
//             ))}
//           </Box>
//         </Box>

//         {isLogout && (
//           <Dialog
//             maxWidth="sm"
//             fullWidth
//             className={classes.dailogOpen}
//             open={isLogout}
//             TransitionComponent={Transition}
//             keepMounted
//             onClose={() => setIsLogout(false)}
//           >
//             <DialogTitle id="form-dialog-title">
//               <Box className="displaySpacebetween">
//                 <Box className="titleBox">
//                   <Typography variant="h5">Logout</Typography>
//                 </Box>
//                 <IconButton className="icon" onClick={() => setIsLogout(false)}>
//                   <CloseIcon />
//                 </IconButton>
//               </Box>
//             </DialogTitle>
//             <DialogContent>
//               <Box className={classes.dialougTitle} align="center">
//                 {/* <AiOutlineLogout
//                   style={{
//                     marginRight: "16px",
//                     color: "#EC1F24",
//                     fontSize: "65px",
//                   }}
//                 /> */}

//                 <Typography style={{ marginTop: "10px" }}>
//                   {" "}
//                   <img src="images/logout.png" />
//                 </Typography>
//                 <Typography
//                   variant="h5"
//                   style={{
//                     color: "#222222",
//                     fontFamily: "poppins",
//                     fontWeight: "400",
//                     maxWidth: "275px",
//                   }}
//                 >
//                   Are you sure, you want to logout?
//                 </Typography>
//               </Box>
//             </DialogContent>
//             <DialogActions
//               style={{
//                 alignContent: "center",
//                 justifyContent: "center",
//               }}
//             >
//               <Box mt={2} mb={2} style={{ gap: "22px" }}>
//                 <Button
//                   className="modelbtn"
//                   color="secondary"
//                   variant="contained"
//                   style={{ padding: "8px 21px", width: "100px" }}
//                   onClick={() => setIsLogout(false)}
//                 >
//                   No
//                 </Button>
//                 &nbsp; &nbsp;
//                 <Button
//                   className="modelbtnyes"
//                   style={{
//                     padding: "8px 21px",
//                     width: "100px",
//                   }}
//                   color="primary"
//                   variant="contained"
//                   fullWidth
//                   onClick={() => {
//                     window.localStorage.removeItem("token");
//                     window.localStorage.removeItem("changeTab");
//                     window.sessionStorage.removeItem("adminToken");
//                     auth.userLogIn(false, null);
//                     history.push("/");
//                   }}
//                 >
//                   Yes
//                 </Button>
//               </Box>
//             </DialogActions>
//           </Dialog>
//         )}
//       </PerfectScrollbar>
//       {console.log(auth.userData?.subAdminPermission?.length)}
//       {auth.userData?.subAdminPermission?.length > 0 && <>Hello</>}
//     </Box>
//   );

//   return (
//     <>
//       <Hidden lgUp>
//         <Drawer
//           anchor="left"
//           classes={{ paper: classes.mobileDrawer }}
//           onClose={onMobileClose}
//           open={openMobile}
//           variant="temporary"
//         >
//           <Box p={2}>{content}</Box>
//         </Drawer>
//       </Hidden>
//       <Hidden mdDown>
//         <Paper elevation={2}>
//           <Drawer
//             anchor="left"
//             classes={{ paper: classes.desktopDrawer }}
//             open
//             variant="persistent"
//           >
//             {content}
//           </Drawer>
//         </Paper>
//       </Hidden>
//     </>
//   );
// };

// NavBar.propTypes = {
//   onMobileClose: PropTypes.func,
//   openMobile: PropTypes.bool,
// };

// export default NavBar;





import React, { useEffect, useState, useContext } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  Hidden,
  List,
  Button,
  ListSubheader,
  makeStyles,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  Paper,
  IconButton,
  ListItem,
  DialogTitle,
  Avatar,
  useMediaQuery,
  // handleClose,
} from "@material-ui/core";
import NavItem from "./NavItem";
import { AiOutlineLogout } from "react-icons/ai";
import { AuthContext } from "src/context/Auth";
import { BiLogOut } from "react-icons/bi";
import { MdOutlineDashboard } from "react-icons/md";
import { TbLogout } from "react-icons/tb";
import { RiAdvertisementFill } from "react-icons/ri";
import { RiCoupon2Fill } from "react-icons/ri";
import { MdAdminPanelSettings } from "react-icons/md";
import { FaUserTie } from "react-icons/fa";
import { GrMultimedia } from "react-icons/gr";
import { GrTasks } from "react-icons/gr";
import { AiFillProject } from "react-icons/ai";
import { FaFileContract } from "react-icons/fa6";
import { TbTransfer } from "react-icons/tb";
import { MdOutlineQueryStats } from "react-icons/md";
import { MdSubscriptions } from "react-icons/md";
import { FaMoneyBill } from "react-icons/fa6";
import { FaWallet } from "react-icons/fa6";
import { FaRegBell } from "react-icons/fa6";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import { CiChat1 } from "react-icons/ci";
import { CgProfile } from "react-icons/cg";
import { TbNotes } from "react-icons/tb";
import { FaNetworkWired } from "react-icons/fa6";
import CloseIcon from "@material-ui/icons/Close";
const sections = [
  {
    items: []
  }
];

const newsections = [
  {
    items: [
      {
        title: "Dashboard",
        modules: "dashboard",
        icon: MdOutlineDashboard,
        href: "/dashboard",
      },
      {
        title: "User Management",
        modules: "subAdmins",
        icon: FaUserTie,
        href: "/user-management",
      },
      {
        title: "Media Management",
        modules: "dashboard",
        icon: GrMultimedia,
        items: [
          {
            title: "Category",
            href: "/category-list",
          },
          {
            title: "Theme",
            href: "/theme-list",
          },
          {
            title: "Topic",
            href: "/topic-list",
          },
          {
            title: "Media Listing",
            href: "/media-listing",
          },
        ],
      },
      {
        title: "Job Management",
        modules: "dashboard",
        icon: GrTasks,
        items: [
          // {
          //   title: "Expertise Management",
          //   href: "/service-category-list",
          // },
          {
            title: "Skills Management",
            href: "/Skills-list",
          },
          {
            title: "Service Category",
            href: "/service-category-list",
          },
          {
            title: "Job List",
            href: "/job-list",
          },
          {
            title: "Completed Job",
            href: "/completed-job-list",
          },
        ],
      },
      {
        title: "Advertisement Management",
        modules: "dashboard",
        icon: RiAdvertisementFill,
        href: "/advertisement-management",
      },
      {
        title: "Project Management",
        modules: "dashboard",
        icon: AiFillProject,
        href: "/project-managment",
      },
      {
        title: "Contract Management",
        modules: "dashboard",
        icon: FaFileContract,
        href: "/contract-management",
      },
      {
        title: "Dispute Management",
        modules: "dashboard",
        icon: FaMoneyBill,
        href: "/dispute-management",
      },

      {
        title: "Transaction Management",
        modules: "dashboard",
        icon: TbTransfer,
        href: "/transaction-management",
      },
      {
        title: "Query Management",
        modules: "dashboard",
        icon: MdOutlineQueryStats,
        href: "/query-management",
      },
      {
        title: "Subscription Management",
        modules: "dashboard",
        icon: MdSubscriptions,
        href: "/subscription-list",
      },
      {
        title: "USSD Management",
        modules: "dashboard",
        icon: RiCoupon2Fill,
        href: "/ussd-management",
      },
      {
        title: "Commision Management",
        modules: "dashboard",
        icon: FaMoneyBill,
        href: "/commission-management",
      },
      {
        title: "Wallet Management",
        modules: "dashboard",
        icon: FaWallet,
        href: "/wallet-management",
      },

      {
        title: "Referral Management",
        modules: "dashboard",
        icon: FaNetworkWired,
        href: "/referral-management",
      },
      {
        title: "Sub Admin Management",
        modules: "dashboard",
        icon: SupervisorAccountIcon,
        href: "/sub-admin-management",
      },
      {
        title: "Static Content Management",
        modules: "dashboard",
        icon: TbNotes,
        href: "/static-content-list",
      },
      {
        title: "My Account",
        modules: "dashboard",
        icon: CgProfile,
        href: "/view-profile",
      },
      {
        title: "Notification",
        modules: "dashboard",
        icon: FaRegBell,
        href: "/notification-management",
      },
      {
        title: "Chat",
        modules: "dashboard",
        icon: CiChat1,
        href: "/admin-chat",
      },
    ],
  },
];
const sections2 = [
  {
    items: [
      {
        title: "LOGOUT",
        modules: "Logout",
        icon: TbLogout,
        href: "logout",
      },
    ],
  },
];

function renderNavItems({
  items,
  pathname,
  depth = 0,
  handleLogoutClick,
  setIsLogout,
}) {
  return (
    <List disablePadding>
      {console.log(handleLogoutClick, "items==>")}
      {/* {console.log(pathname, "items==>")}
      {console.log(depth, "items==>")} */}
      {items.reduce(
        (acc, item) =>
          reduceChildRoutes({ acc, item, pathname, depth, setIsLogout }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth, setIsLogout }) {
  const key = item.title + depth;
  const match = (item.items || []).some((child) =>
    matchPath(pathname, {
      path: child.href,
      exact: true,
    })
  );

  if (item.items) {
    const open =
      matchPath(pathname, {
        path: item.href,
        exact: false,
      }) || match;
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={item.title === "LOGOUT" ? setIsLogout(true) : Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }
  return acc;
}
const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: "#fff",
  },
  adminName: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "7px",

    "& h6": {
      marginTop: "7px",
    },
    "& .MuiAvatar-root": {
      width: "75px",
      height: "75px",
    },
  },
  sectionsHeading: {
    padding: "2px 0px 0px 22px",
    lineHeight: "normal",
    color: "#7C7C7C",
    fontSize: "12px",
  },
  desktopDrawer: {
    // boxShadow: "1px 0px 5px 0px rgba(0,0,0,0.75)",
    boxShadow: "10px 0 10px rgba(0, 0, 0, 0.1)",
    top: "76px",
    width: "300px",
    height: "88vh",
    marginTop: "6px",
    background: "#fff",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  button: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    height: "45px",
    paddingLeft: "17px",
    borderRadius: "12px",
    marginTop: "-30px",
    "&:hover": {
      color: "#F5C843",
    },
    "& svg": {
      color: "#F5C843",
      fontSize: "20px",
    },
  },

  btnBox: {
    position: "relative",
    left: "30%",
    bottom: "-250px",
  },
  logoutButton: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    position: "absolute",
    bottom: "19px",
    left: "17px",
    background: "transparent",
    fontWeight: "400",
    fontSize: "13px",
    color: "#EC1F24",
  },
  sideMenuBox: {
    "& .MuiCollapse-wrapperInner": {
      marginLeft: "45px",
    },
  },
  dailogOpen: {
    "& .MuiDialog-paperWidthSm": {
      width: "652px !important",
    },
    "& .MuiDialogContent-root": {
      padding: "8px 39px",
    },
    "& .MuiDialogTitle-root ": {
      background: "#767676 !important",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "0px ",
      "& .MuiTypography-h6": {
        width: "100%",
      },
    },
    "& .titleBox": { flex: "1", textAlign: "center", padding: "18px 0px" },

    "& .icon": {
      color: "#fff",
      alignItems: "center",
      paddingRight: "25px",
      position: "absolute",
      top: "0px",
      right: "0px",
    },
    "& .MuiButton-text": {
      color: "#ED2124",
    },
    "& .MuiDialogActions-root": {
      justifyContent: "center",
    },
    "& .MuiOutlinedInput-root": {
      height: "auto",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile, handleClose }) => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const location = useLocation();
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  let permission = auth?.userData?.subAdminPermission;
  console.log(auth, "permissionpermission");
  // let connectedExchange = auth.userData?.connectedExchange?.length;
  const history = useHistory();
  const [isLogout, setIsLogout] = useState(false);
  const handleLogoutClick = () => {
    console.log("log");
    setIsLogout(true);
  };
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]); // eslint-disable-line
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  //  const filteredSections = sections.filter((section) => {
  //    return permission?.[section.title.toLowerCase()] === true;
  //  });
  //  console.log(filteredSections, "filteredSections");

  let fname = auth?.userData?.firstName?.length;
  let fname1 = auth?.userData?.firstName ?? "";
  let lname = auth?.userData?.lastName?.length;
  let lname1 = auth?.userData?.lastName ?? "";

  let fname2 = fname1;
  let lname2 = lname1;
  if (fname > 5) {
    fname2 = fname1.slice(0, 5) + "...";
    console.log(fname2 + "...");
  }
  if (lname > 5) {
    lname2 = lname1.slice(0, 5) + "...";
    console.log(lname2 + "...");
  }

  function normalizeTitle(title) {
    return title.replace(/\s+/g, '');
  }
  
  function capitalizeFirstLetter(str) {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
  }
  
  function initializePermissions() {
    const userType = auth.userData?.userType;
    if (userType === 'SUBADMIN') {
      const subAdminPermission = auth.userData?.subAdminPermission[0];
      const trueModules = Object.keys(subAdminPermission)
        .filter(key => subAdminPermission[key])
        .map(key => capitalizeFirstLetter(key));
  
      filterTrueModules(trueModules);
    } else {
      sections[0] = { ...newsections[0] };
    }
  }
  
  function filterTrueModules(trueModules) {
    sections[0].items = newsections.flatMap(section =>
      section.items.filter(item =>
        trueModules.includes(normalizeTitle(item.title))
      )
    );
  }
  
  initializePermissions();
  

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box className={classes.adminName}>
          <Avatar src={auth?.userData?.profilePic ?? ""} alt="" />
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="h6">
              {/* {
              // let name= auth?.userData?.firstName.length
            } */}
              {/* {fname1.slice(0, 10)} {lname1.slice(0, 10)} */}
              {fname1.length > 10 ? `${fname1.slice(0, 10)}...` : fname1} {lname1.length > 10 ? `${lname1.slice(0, 10)}...` : lname1}
              {/* {auth?.userData?.firstName ?? ""} {auth?.userData?.lastName ?? ""} */}
            </Typography>
          </Box>
        </Box>
        <Box pt={2} pb={2}>
          <Box
            className="sideMenuBox"
            style={{
              boxShadow: "0px 23.306px 58.264px 0px rgba(220, 224, 249, 0.50)",
            }}
          >
            {[
              { sections: sections, heading: null },
              { sections: sections2, heading: null },
            ]?.map((category, categoryIndex) => (
              <React.Fragment key={`category${categoryIndex}`}>
                <Typography className={classes.sectionsHeading}>
                  {category.heading}
                </Typography>
                {categoryIndex === 1 ? (
                  <List
                    subheader={
                      <ListSubheader disableGutters disableSticky>
                        {category.sections[0]?.subheader}
                      </ListSubheader>
                    }
                  >
                    {category.sections[0]?.items.map((data, j) => (
                      <ListItem
                        button
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "25px",
                          marginLeft: isSmallScreen ? "5px" : "36px",
                        }}
                        key={`item${j}`}
                        selected={location.pathname === data.href}
                        onClick={() => {
                          data.title !== "LOGOUT"
                            ? history.push(data.href)
                            : handleLogoutClick();
                        }}
                      >
                        <BiLogOut />
                        <span>{data.title}</span>
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  renderNavItems({
                    img: category.sections[0]?.img,
                    items: category.sections[0]?.items || [],
                    pathname: location.pathname,
                    handleLogoutClick,
                  })
                )}
              </React.Fragment>
            ))}
          </Box>
        </Box>

        {isLogout && (
          <Dialog
            maxWidth="sm"
            fullWidth
            className={classes.dailogOpen}
            open={isLogout}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setIsLogout(false)}
          >
            <DialogTitle id="form-dialog-title">
              <Box className="displaySpacebetween">
                <Box className="titleBox">
                  <Typography variant="h5">Logout</Typography>
                </Box>
                <IconButton className="icon" onClick={() => setIsLogout(false)}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent>
              <Box className={classes.dialougTitle} align="center">
                {/* <AiOutlineLogout
                  style={{
                    marginRight: "16px",
                    color: "#EC1F24",
                    fontSize: "65px",
                  }}
                /> */}

                <Typography style={{ marginTop: "10px" }}>
                  {" "}
                  <img src="images/logout.png" />
                </Typography>
                <Typography
                  variant="h5"
                  style={{
                    color: "#222222",
                    fontFamily: "poppins",
                    fontWeight: "400",
                    maxWidth: "275px",
                  }}
                >
                  Are you sure, you want to logout?
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions
              style={{
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Box mt={2} mb={2} style={{ gap: "22px" }}>
                <Button
                  className="modelbtn"
                  color="secondary"
                  variant="contained"
                  style={{ padding: "8px 21px", width: "100px" }}
                  onClick={() => setIsLogout(false)}
                >
                  No
                </Button>
                &nbsp; &nbsp;
                <Button
                  className="modelbtnyes"
                  style={{
                    padding: "8px 21px",
                    width: "100px",
                  }}
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    window.localStorage.removeItem("token");
                    window.localStorage.removeItem("changeTab");
                    window.sessionStorage.removeItem("adminToken");
                    auth.userLogIn(false, null);
                    history.push("/");
                  }}
                >
                  Yes
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        )}
      </PerfectScrollbar>
      {console.log(auth.userData?.subAdminPermission?.length)}
      {/* {auth.userData?.subAdminPermission?.length > 0 && <>Hello</>} */}
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Box p={2}>{content}</Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper elevation={2}>
          <Drawer
            anchor="left"
            classes={{ paper: classes.desktopDrawer }}
            open
            variant="persistent"
          >
            {content}
          </Drawer>
        </Paper>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;

