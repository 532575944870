const url = "https://nodepune-experienced.mobiloitte.io/api/v1";
// const url = "http://172.16.6.113:2020/api/v1"; // rishabh local
// const url = "http://172.16.6.83:2020/api/v1"; // izhar local
// const url = "http://172.16.6.125:2020/api/v1"; // naman jha
// const url = "http://172.16.1.128:2020/api/v1"; // Harsh Local

export const agoraAppId = process.env.REACT_APP_AGORA_APP_ID;

const ApiConfig = {


  //auth
  AdminLogin: `${url}/admin/adminlogin`,
  forgotPassword: `${url}/admin/forgotPassword`,
  verifyOTP: `${url}/admin/verifyOTP`,
  resendOtp: `${url}/admin/resendOtp`,
  resetPassword: `${url}/admin/resetPassword`,
  getAdminProfile: `${url}/admin/getProfile`,
  getPortfolio: `${url}/user/getPortfolio`,
  changePasswordAdmin: `${url}/admin/changePassword`,
  editAdminProfile: `${url}/user/editAdminProfile`,

  //socket
  chatHistory: `${url}/socket/chatHistory`,
  oneToOneChatApi: `${url}/socket/oneToOneChatApi`,
  oneGroupToOneChatApi: `${url}/socket/oneGroupToOneChatApi`,
  listUserSocket: `${url}/socket/listUser`,
  agoraTokenGenerator: `${url}/socket/agoraTokenGenerator`,
  clearChat: `${url}/socket/clearChat`,
  callUser: `${url}/socket/callUser`,
  GroupMembers: `${url}/socket/createGroup`,
  DeleteMessage: `${url}/socket/deleteMessage`,



  //dashboard
  dashboard: `${url}/admin/dashboard`,
  individualOrOeganizationBar: `${url}/admin/individualOrOeganizationBar`,

  //subadmin
  viewSubAdmin: `${url}/admin/viewSubAdmin`,
  adminCreateSubAdmin: `${url}/admin/adminCreateSubAdmin`,
  editSubAdmin: `${url}/admin/editSubAdmin`,

  //static content
  staticContentList: `${url}/static/staticContentList`,
  viewStaticContent: `${url}/static/viewStaticContent`,
  addStaticContent: `${url}/static/addStaticContent`,
  editStaticContent: `${url}/static/editStaticContent`,
  getAllBanners: `${url}/banner/get-all-banners`,
  addBanner: `${url}/banner/addBanner`,
  editBanner: `${url}/banner/editBanner`,
  deleteBanner: `${url}/banner/deleteBanner`,
  teamContentList: `${url}/static/teamContentList`,
  getAllDemos: `${url}/demo/getAllDemos`,
  getdemo: `${url}/demo/getdemo`,
  editdemo: `${url}/demo/editdemo`,
  createTeam: `${url}/static/createTeam`,
  editTeam: `${url}/static/editTeam`,
  deleteTeam: `${url}/static/deleteTeam`,
  viewTeam: `${url}/static/viewTeam`,

  //user-management
  listUser: `${url}/admin/listUser`,
  blockUnblockUser: `${url}/admin/blockUnblockUser`,
  viewUserProfile: `${url}/admin/viewUserProfile`,
  listAdvertiseRequest: `${url}/advertisement/listAdvertiseRequest`,
  viewAdvertiseDetail: `${url}/advertisement/viewAdvertiseDetail`,
  listAdminAdvertise: `${url}/advertisement/listAdminAdvertise`,
  uploadFile: `${url}/user/uploadFiles`,
  assignedProject: `${url}/project/contractProjectListById`,
  AssignedWork: `${url}/job/assignedWorkToServiceProvider`,
  GeneralView: `${url}/feedback/feedbackList`,
  UserCommissionByAdmin: `${url}/commission/userCommissionByAdmin`,
  graphRevenue: `${url}/milestone/revenueData`,

  //media management
  createCategory: `${url}/category/createCategory`,
  createtheme: `${url}/theme/createtheme`,
  editTheme: `${url}/theme/editTheme`,
  createtopic: `${url}/theme/createtopic`,
  editTopic: `${url}/theme/editTopic`,
  listTheme: `${url}/theme/listTheme`,
  listAllTheme: `${url}/theme/listAllTheme`,
  mediaListAdmin: `${url}/admin/mediaListAdmin`,
  categoryList: `${url}/category/categoryList`,
  viewmedia: `${url}/media/viewmedia`,
  blockUnblockCategory: `${url}/category/blockUnblockCategory`,
  deletecategory: `${url}/category/deletecategory`,
  blockUnblockTheme: `${url}/theme/blockUnblockTheme`,
  deletetheme: `${url}/theme/deletetheme`,
  listAllTopic: `${url}/theme/listAllTopic`,
  deletetopic: `${url}/theme/deletetopic`,
  blockUnblockTopic: `${url}/theme/blockUnblockTopic`,
  approveOrReject: `${url}/media/approveOrReject`,
  commentMedia: `${url}/media/commentMedia`,
  editCategory: `${url}/category/editCategory`,
  blockUnblockMedia: `${url}/media/blockUnblockMedia`,
  centerMediaList: `${url}/media/centerMediaList`,
  viewmediaContract: `${url}/media/viewmediaContract`,

  //job management
  listServiceCategory: `${url}/serviceCategory/listServiceCategory`,
  createServiceCategory: `${url}/serviceCategory/createServiceCategory`,
  viewServiceCategory: `${url}/serviceCategory/viewServiceCategory`,
  jobListByAdmin: `${url}/job/jobListByAdmin`,
  jobListByAdminCompleted: `${url}/job/jobListByAdminCompleted`,
  adminDeliverOrComment: `${url}/job/adminDeliverOrComment`,

  blockUnblockserviceCategory: `${url}/serviceCategory/blockUnblockserviceCategory`,
  deleteCategory: `${url}/serviceCategory/deleteCategory`,
  editServiceCategory: `${url}/serviceCategory/editServiceCategory`,
  viewjob: `${url}/job/viewjob`,
  jobApproveOrReject: `${url}/job/approveOrReject`,
  adminApproveOrReject: `${url}/job/adminApproveOrReject`,
  createjob: `${url}/job/createjob`,
  createJobsForAdmin: `${url}/job/createJobsForAdmin`,
  listServiceProvider: `${url}/serviceProvider/listServiceProvider`,
  jobContractCreate: `${url}/job/jobContractCreateByAdmin`,
  viewjobContract: `${url}/job/viewjobContract`,
  createMilestone: `${url}/milestone/createMilestone`,
  listAllMilestone: `${url}/milestone/listAllMilestone`,
  commentMilestone: `${url}/milestone/commentMilestone`,
  sendPaymentRequest: `${url}/milestone/sendPaymentRequest`,
  workApproveByAdmin: `${url}/milestone/workApproveByAdmin`,
  jobContractEditByAdmin: `${url}/job/jobContractEditByAdmin`,
  jobContractUpdateByAdmin: `${url}/job/jobContractUpdateByAdmin`,
  assignView: `${url}/job/viewjob`,
  createJobView: `${url}/job/viewjob`,

  //Skill
  deleteSkill: `${url}/admin/deleteSkill`,
  EditSkill: `${url}/admin/editSkill`,
  skillsList: `${url}/admin/skillsList`,
  createSkill: `${url}/admin/createSkill`,
  blockSkill: `${url}/admin/blockUnblockSkill`,

  //Advertise
  reqAdvList: `${url}/advertisement/listAdvertise`,
  AdvList: `${url}/advertisement/listAdvertiseRequest`,
  viewAdvDetail: `${url}/advertisement/viewAdvertiseDetail`,
  updateReqAdvDetail: `${url}/advertisement/approveOrReject`,

  //USSD
  menuList: `${url}/menu/listMenu`,
  menuAdd: `${url}/menu/addMenu`,
  deleteMenu: `${url}/menu/deleteMenu`,
  blockUnblockMenu: `${url}/menu/blockUnblockMenu`,
  updateMenu: `${url}/menu/updateMenu`,

  //project managment
  projectListforAdmin: `${url}/project/projectListforAdmin`,
  viewproject: `${url}/project/viewproject`,
  projectapproveOrReject: `${url}/project/approveOrReject`,
  deliverOrCommentAdmin: `${url}/project/deliverOrCommentAdmin`,

  //Sub-Admin
  listSubAdmin: `${url}/admin/listSubAdmin`,
  blockUnblockSubAdmin: `${url}/admin/blockUnblockSubAdmin`,
  deleteSubAdmin: `${url}/admin/deleteSubAdmin`,

  //Query-Management
  listContactUs: `${url}/contact/listContactUs`,
  viewcontactus: `${url}/contact/viewcontactus`,
  replyContactUs: `${url}/contact/replyContactUs`,

  //Subscription Management
  subscriptionList: `${url}/admin/subscriptionList`,
  view: `${url}/admin/view`,
  planList: `${url}/admin/planList`,
  edit: `${url}/admin/edit`,
  viewsubscription: `${url}/admin/viewSubscription`,
  deletePlan: `${url}/admin/deletePlan`,
  deletesubScriptionPlan: `${url}/admin/deletesubScriptionPlan`,
  blockUnblocksubScriptionPlan: `${url}/admin/blockUnblocksubScriptionPlan`,
  blockUnblockPlan: `${url}/admin/blockUnblockPlan`,
  ActiveExpire: `${url}/user/updateData`,

  //Contract Management
  mediaList: `${url}/media/mediaList`,
  // viewmedia: `${url}/media/viewMedia`,
  mediaContractCreates: `${url}/media/mediaContractCreates`,
  advertisementContractCreates: `${url}/advertisement/advertisementContractCreates`,
  projectContractCreates: `${url}/project/projectContractCreates`,
  viewprojectContract: `${url}/project/viewprojectContract`,
  createProjectMilestone: `${url}/project/createProjectMilestone`,
  listProjectMilestones: `${url}/project/listProjectMilestones`,
  projectContractEdit: `${url}/project/projectContractEdit`,
  projectContractUpdate: `${url}/project/projectContractUpdate`,

  //Commision Management

  listCommission: `${url}/commission/listCommission`,
  createcommission: `${url}/commission/createCommission`,
  commissionHistory: `${url}/commission/commissionHistory`,
  commissionById: `${url}/commission/commissionById`,
  totalCommission: `${url}/commission/totalCommission`,

  //referral Management
  adminListAffilate: `${url}/user/adminListAffilate`,
  createPoints: `${url}/commission/createPoints`,
  listPoints: `${url}/commission/listPoint`,

  //transaction managment
  transactionListAdmin: `${url}/user/transactionListAdmin`,
  getTransaction: `${url}/user/getTransaction`,
  categoryCenterList: `${url}/category/categoryCenterList`,

  //Disputes
  listAllDisputes: `${url}/dispute/listAllDisputes`,
  adminTakeDispute: `${url}/dispute/adminTakeDispute`,

  // wallet

  getwithdrawRequestByAdmin: `${url}/payment/getwithdrawRequestByAdmin`,
  payToUser: `${url}/payment/payToUser`,

  // /notification management

  listNotification: `${url}/notification/listNotification`,
  viewNotification: `${url}/notification/viewNotification`,
  deleteNotification: `${url}/notification/deleteNotification`,
  clearAllNotification: `${url}/notification/clearAllNotification`,

};

export default ApiConfig;
