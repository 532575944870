import React from "react";
import { makeStyles } from "@material-ui/core";
// import { useHistory } from "react-router-dom";
import Footer from "./Footer";
import TopBar from "./TopBar";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",

    "& .wrapper": {
      display: "flex",
      flex: "1 1 auto",
      overflow: "hidden",
      position: "relative",
      backgroundColor: "#eef4f8",
      // paddingTop: 100,
      minHeight: "calc(100vh - 75px)",
      // "@media (max-width:767px)": {
      //   paddingTop: "70px !important",
      // },
    },

    "& .paddingWrapper": {
      display: "flex",
      flex: "1 1 auto",
      overflow: "hidden",
      position: "relative",
      backgroundColor: "#eef4f8",
      paddingTop: 96,
      minHeight: "calc(100vh - 75px)",
    },

    "& .contentContainer": {
      display: "flex",
      flex: "1 1 auto",
      overflow: "hidden",
    },

    "& .content": {
      flex: "1 1 auto",
      height: "100%",
      overflow: "hidden",
      position: "relative",
      // padding: "28px 0px ",
      // [theme.breakpoints.down("md")]: {
      //   padding: "25px 10px 10px ",
      // },
      // [theme.breakpoints.down("xs")]: {
      //   padding: "10px 10px 10px ",
      // },
    },
  },

  MainLayout: {
    minHeight: "calc(100vh - 415px)",
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  // const history = useHistory();

  const noPaddingRoute = window.location.pathname === "/";
  const registerRoute = window.location.pathname === "/register";

  return (
    <div className={classes.root}>
      {registerRoute ? (
        <>
          <TopBar />

          <div className={noPaddingRoute ? "paddingWrapper" : "wrapper"}>
            <div className="contentContainer">
              <div className="content">{children}</div>
            </div>
          </div>
        </>
      ) : (
        <>
          {" "}
          <TopBar />
          <div className={noPaddingRoute ? "paddingWrapper" : "wrapper"}>
            <div className="contentContainer">
              <div className="content">{children}</div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </div>
  );
};

export default MainLayout;
