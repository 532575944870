import React, { createContext, useEffect, useState } from "react";



export const UserContext = createContext();




export default function AuthProvider(props) {
    const [userId, setUerId] = useState();  
    let data = {
     userId,
     setUerId
    };
  
    return (
      <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
    );
  }
