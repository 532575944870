import { Link } from "@material-ui/core";
import React from "react";

export const menuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  elevation: 0,
  PaperProps: {
    style: {
      maxHeight: 200,
    },
  },
};

export const localeFunction = (key) => {
  console.log(key,"key")
  return require(`src/utils/lang/en`)[key];
};
export function generateTimestamp() {
  const now = new Date();
  const timestamp = `${now.getFullYear()}${(now.getMonth() + 1)
    .toString()
    .padStart(2, "0")}${now.getDate().toString().padStart(2, "0")}_${now
    .getHours()
    .toString()
    .padStart(2, "0")}${now.getMinutes().toString().padStart(2, "0")}${now
    .getSeconds()
    .toString()
    .padStart(2, "0")}`;
  return timestamp;
}

export const Tabledata = [
  {
    srNo: 1,
    mediaName: "Aina Za Umiliki Wa Biashara",
    userName: "Abhinav",
    orderedDateTime: "Jul 4, 2023,6:22:32 PM",
    paidAmount: "10",
    downloadedStatus: "YES",
  },
  {
    srNo: 2,
    mediaName: "Aina Za Umiliki Wa Biashara",
    userName: "Abhinav",
    orderedDateTime: "Jul 4, 2023,6:22:32 PM",
    paidAmount: "10",
    downloadedStatus: "YES",
  },
  {
    srNo: 3,
    mediaName: "Aina Za Umiliki Wa Biashara",
    userName: "Abhinav",
    orderedDateTime: "Jul 4, 2023,6:22:32 PM",
    paidAmount: "10",
    downloadedStatus: "YES",
  },
  {
    srNo: 4,
    mediaName: "Aina Za Umiliki Wa Biashara",
    userName: "Abhinav",
    orderedDateTime: "Jul 4, 2023,6:22:32 PM",
    paidAmount: "10",
    downloadedStatus: "YES",
  },
  {
    srNo: 5,
    mediaName: "Aina Za Umiliki Wa Biashara",
    userName: "Abhinav",
    orderedDateTime: "Jul 4, 2023,6:22:32 PM",
    paidAmount: "10",
    downloadedStatus: "YES",
  },
  {
    srNo: 6,
    mediaName: "Aina Za Umiliki Wa Biashara",
    userName: "Abhinav",
    orderedDateTime: "Jul 4, 2023,6:22:32 PM",
    paidAmount: "10",
    downloadedStatus: "YES",
  },
  {
    srNo: 7,
    mediaName: "Aina Za Umiliki Wa Biashara",
    userName: "Abhinav",
    orderedDateTime: "Jul 4, 2023,6:22:32 PM",
    paidAmount: "10",
    downloadedStatus: "YES",
  },
  {
    srNo: 8,
    mediaName: "Aina Za Umiliki Wa Biashara",
    userName: "Abhinav",
    orderedDateTime: "Jul 4, 2023,6:22:32 PM",
    paidAmount: "10",
    downloadedStatus: "YES",
  },
];

export const chatData = [
  {
    image: <img src="images/ChatDP.png" alt="DP" />,
    name: "Tony Johnson",
    date: "Nov 26",
    msg: "I am intrested in buy your product.",
  },
  {
    image: <img src="images/ChatDP.png" alt="DP" />,
    name: "Tony Johnson",
    date: "Nov 26",
    msg: "I am intrested in buy your product.",
  },
  {
    image: <img src="images/ChatDP.png" alt="DP" />,
    name: "Tony Johnson",
    date: "Nov 26",
    msg: "I am intrested in buy your product.",
  },
  {
    image: <img src="images/ChatDP.png" alt="DP" />,
    name: "Tony Johnson",
    date: "Nov 26",
    msg: "I am intrested in buy your product.",
  },
  {
    image: <img src="images/ChatDP.png" alt="DP" />,
    name: "Tony Johnson",
    date: "Nov 26",
    msg: "I am intrested in buy your product.",
  },
  {
    image: <img src="images/ChatDP.png" alt="DP" />,
    name: "Tony Johnson",
    date: "Nov 26",
    msg: "I am intrested in buy your product.",
  },
];

export const senderData = [
  {
    msg: "It is a long established fact  will be looking at its layout.",
  },
  {
    msg: "It is a long established fact  will be looking at its layout.",
  },
];

export const ceateGroupData = [
  {
    image: <img src="images/ChatDP.png" alt="DP" />,
    name: "Tony Johnson ",
    profession: "(Consumer)",
  },
  {
    image: <img src="images/ChatDP.png" alt="DP" />,
    name: "Tony Johnson ",
    profession: "(Service Provider)",
  },
  {
    image: <img src="images/ChatDP.png" alt="DP" />,
    name: "Tony Johnson ",
    profession: "(Partner)",
  },
  {
    image: <img src="images/ChatDP.png" alt="DP" />,
    name: "Tony Johnson ",
    profession: "(Service Provider)",
  },
  {
    image: <img src="images/ChatDP.png" alt="DP" />,
    name: "Tony Johnson ",
    profession: "(Service Provider)",
  },
  {
    image: <img src="images/ChatDP.png" alt="DP" />,
    name: "Tony Johnson ",
    profession: "(Service Provider)",
  },
  {
    image: <img src="images/ChatDP.png" alt="DP" />,
    name: "Tony Johnson ",
    profession: "(Service Provider)",
  },
];

export const workListData = [
  {
    no: 1,
    provider: "Aina Za Umiliki Wa Biashara",
    type: "CleanUp",
    price: 100,
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Pending",
    action: "VIEW",
  },
  {
    no: 2,
    provider: "Aina Za Umiliki Wa Biashara",
    type: "NIBI Country",
    price: "PAID",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Completed",
    action: "VIEW",
  },
  {
    no: 3,
    provider: "Aina Za Umiliki Wa Biashara",
    type: "NIBI Country",
    price: "FREE",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Accepted",
    action: "VIEW",
  },
  {
    no: 4,
    provider: "Aina Za Umiliki Wa Biashara",
    type: "NIBI Country",
    price: "PAID",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "In Progress",
    action: "VIEW",
  },
  {
    no: 5,
    provider: "Aina Za Umiliki Wa Biashara",
    type: "NIBI Country",
    price: "FREE",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Pending",
    action: "VIEW",
  },
  {
    no: 6,
    provider: "Aina Za Umiliki Wa Biashara",
    type: "NIBI Country",
    price: "PAID",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Published",
    action: "VIEW",
  },
  {
    no: 7,
    provider: "Aina Za Umiliki Wa Biashara",
    type: "NIBI Country",
    price: "FREE",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Pending",
    action: "VIEW",
  },
  {
    no: 8,
    provider: "Aina Za Umiliki Wa Biashara",
    type: "NIBI Country",
    price: "PAID",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Published",
    action: "VIEW",
  },
  {
    no: 9,
    provider: "Aina Za Umiliki Wa Biashara",
    type: "NIBI Country",
    price: "FREE",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Pending",
    action: "VIEW",
  },
  {
    no: 10,
    provider: "Aina Za Umiliki Wa Biashara",
    type: "NIBI Country",
    price: "PAID",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Published",
    action: "VIEW",
  },
];

export const transactionData = [
  {
    no: 1,
    provider: "TTCNI022000800594",
    username: "Abhinav",
    date: "24th July, 2023 13:09",
    type: "Podcast",
    paidAmount: "10",
    action: "VIEW",
  },
  {
    no: 2,
    provider: "TTCNI022000800594",
    username: "Abhinav",
    date: "24th July, 2023 13:09",
    type: "Service",
    paidAmount: "10",
    action: "VIEW",
  },
  {
    no: 3,
    provider: "TTCNI022000800594",
    username: "Abhinav",
    date: "24th July, 2023 13:09",
    type: "Podcast",
    paidAmount: "10",
    action: "VIEW",
  },
  {
    no: 4,
    provider: "TTCNI022000800594",
    username: "Abhinav",
    date: "24th July, 2023 13:09",
    type: "Podcast",
    paidAmount: "10",
    action: "VIEW",
  },
  {
    no: 5,
    provider: "TTCNI022000800594",
    username: "Abhinav",
    date: "24th July, 2023 13:09",
    type: "Service",
    paidAmount: "10",
    action: "VIEW",
  },
  {
    no: 6,
    provider: "TTCNI022000800594",
    username: "Abhinav",
    date: "24th July, 2023 13:09",
    type: "Podcast",
    paidAmount: "10",
    action: "VIEW",
  },
  {
    no: 7,
    provider: "TTCNI022000800594",
    username: "Abhinav",
    date: "24th July, 2023 13:09",
    type: "Service",
    paidAmount: "10",
    action: "VIEW",
  },
  {
    no: 8,
    provider: "TTCNI022000800594",
    username: "Abhinav",
    date: "24th July, 2023 13:09",
    type: "Podcast",
    paidAmount: "10",
    action: "VIEW",
  },
  {
    no: 9,
    provider: "TTCNI022000800594",
    username: "Abhinav",
    date: "24th July, 2023 13:09",
    type: "Podcast",
    paidAmount: "10",
    action: "VIEW",
  },
  {
    no: 10,
    provider: "TTCNI022000800594",
    username: "Abhinav",
    date: "24th July, 2023 13:09",
    type: "Podcast",
    paidAmount: "10",
    action: "VIEW",
  },
];

export const jacksonData = [
  {
    image: <img src="images/Professor.png" alt="professor" />,
    name: "Michael Jackson",
    rating: 4.5,
    starImage: <img src="images/ratingstar.png" alt="rating" />,
    action: "View",
  },
  {
    image: <img src="images/Professor.png" alt="professor" />,
    name: "Michael Jackson",
    rating: 4.5,
    starImage: <img src="images/ratingstar.png" alt="rating" />,
    action: "View",
  },
  {
    image: <img src="images/Professor.png" alt="professor" />,
    name: "Michael Jackson",
    rating: 4.5,
    starImage: <img src="images/ratingstar.png" alt="rating" />,
    action: "View",
  },
];

export const pendingData = [
  {
    title: "Service Provider:",
    info: "Arvind",
    view: <Link>View Details</Link>,
  },
  {
    title: "Service Type:",
    info: "Content Creator",
  },
  {
    title: "Payment Status:",
    info: "Paid",
  },
  {
    title: "Description:",
    info: "creatives specialized in the development of audio and video content. They earn from taking content creation jobs from Fululizo, upon job orders places on Fululizo by content owners, partner organisations, brands and consumers",
  },
  {
    title: "Order Status :",
    info: "Pending",
  },
  {
    title: "Questions and special instructions (if any):",
    info: "creatives specialized in the development of audio and video content.",
  },
  {
    title: "Required Skills :",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing",
  },
  {
    title: "Attachment:",
    info: "https://docs.google.com",
  },
];

export const viewWorkCompletedData = [
  {
    title: "User Name :",
    info: "Arvind",
  },
  {
    title: "User Type :",
    info: "Consumer",
  },
  {
    title: "Service Type",
    info: "Content Creator",
  },
  {
    title: "Payment Status:",
    info: "Paid",
  },
  {
    title: "Description:",
    info: "creatives specialized in the development of audio and video content. They earn from taking content creation jobs from Fululizo, upon job orders places on Fululizo by content owners, partner organisations, brands and consumers",
  },
  {
    title: "Remark :",
    info: "They earn from taking content creation jobs from Fululizo, upon job.",
  },
  {
    title: "File :",
    info: "Aina Za Umiliki Wa Biashara",
    image: <img src="images/imageMP4.png" alt="mp4" />,
  },
];

export const completedData = [
  {
    title: "Service Provider:",
    info: "Arvind",
    view: <Link>View Details</Link>,
  },
  {
    title: "Service Type:",
    info: "Content Creator",
  },
  {
    title: "Payment Status:",
    info: "Paid",
  },
  {
    title: "Description:",
    info: "creatives specialized in the development of audio and video content. They earn from taking content creation jobs from Fululizo, upon job orders places on Fululizo by content owners, partner organisations, brands and consumers",
  },
  {
    title: "Order Status :",
    info: "Pending",
  },
];

export const workDetailsData = [
  {
    title: "Service Provider:",
    info: "Arvind",
  },
  {
    title: "Service Type:",
    info: "Content Creator",
  },
  {
    title: "Payment Status:",
    info: "Paid",
  },
  {
    title: "Description:",
    info: "creatives specialized in the development of audio and video content. They earn from taking content creation jobs from Fululizo, upon job orders places on Fululizo by content owners, partner organisations, brands and consumers",
  },
];

export const contractData = [
  {
    title: "Contract Fees:",
    info: "100 Kenya shiling",
  },
  {
    title: "Contract Duration:",
    info: `1 Month `,
    info2: "(after accepting the contract from both the parties.)",
  },
];
export const servicesData = [
  {
    serviceImage: "images/services_first.svg",
    serviceTitle: "24 Millions Business",
    serviceText:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
    backgroundImage: "images/services_first_back3.png",
  },
  {
    serviceImage: "images/services_second.svg",
    serviceTitle: "1,200 Service Offered",
    serviceText:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
    backgroundImage: "images/services_second_back.png",
  },
  {
    serviceImage: "images/services_third.svg",
    serviceTitle: "05 Millions Visitors",
    serviceText:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
    backgroundImage: "images/services_third_back.png",
  },
  {
    serviceImage: "images/services_fourth.svg",
    serviceTitle: "5+ Development Tool",
    serviceText:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
    backgroundImage: "images/services_fourth_back.png",
  },
];

export const servicesTypeData = [
  {
    serviceImage: "images/service_type1.svg",
    serviceTitle: "CLEAN-UP",
    serviceText:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
  },
  {
    serviceImage: "images/service_type2.svg",
    serviceTitle: "SCRIPTING",
    serviceText:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
  },
  {
    serviceImage: "images/service_type3.svg",
    serviceTitle: "EDITING",
    serviceText:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
  },
  {
    serviceImage: "images/service_type4.svg",
    serviceTitle: "RE-ORGANISE",
    serviceText:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
  },
  {
    serviceImage: "images/service_type5.svg",
    serviceTitle: "TRANSCRIPTION",
    serviceText:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
  },
  {
    serviceImage: "images/service_type6.svg",
    serviceTitle: "VOICE-OVER",
    serviceText:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
  },
  {
    serviceImage: "images/service_type7.svg",
    serviceTitle: "TRIMMING",
    serviceText:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
  },
  {
    serviceImage: "images/service_type8.svg",
    serviceTitle: "TRANSLATION",
    serviceText:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
  },
];

export const creatorsData = [
  {
    creatorImage: "images/creator_img.svg",
    creatorName: "Ronald Ramirez",
    creatorRole: "Founder Of Org.",
    decription:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.",
  },
  {
    creatorImage: "images/creator_img.svg",
    creatorName: "Ronald Ramirez",
    creatorRole: "Founder Of Org.",
    decription:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.",
  },
  {
    creatorImage: "images/creator_img.svg",
    creatorName: "Ronald Ramirez",
    creatorRole: "Founder Of Org.",
    decription:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.",
  },
  {
    creatorImage: "images/creator_img.svg",
    creatorName: "Ronald Ramirez",
    creatorRole: "Founder Of Org.",
    decription:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.",
  },
  {
    creatorImage: "images/creator_img.svg",
    creatorName: "Ronald Ramirez",
    creatorRole: "Founder Of Org.",
    decription:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.",
  },
  {
    creatorImage: "images/creator_img.svg",
    creatorName: "Ronald Ramirez",
    creatorRole: "Founder Of Org.",
    decription:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.",
  },
  {
    creatorImage: "images/creator_img.svg",
    creatorName: "Ronald Ramirez",
    creatorRole: "Founder Of Org.",
    decription:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.",
  },
  {
    creatorImage: "images/creator_img.svg",
    creatorName: "Ronald Ramirez",
    creatorRole: "Founder Of Org.",
    decription:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.",
  },
];

export const chargesData = [
  {
    icon: "images/charges_icon.svg",
    title: "CLEAN-UP",
    description:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
    price: "KES 100 per service",
  },
  {
    icon: "images/charges_icon.svg",
    title: "SCRIPTING",
    description:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
    price: "KES 100 per service",
  },
  {
    icon: "images/charges_icon.svg",
    title: "EDITING",
    description:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
    price: "KES 100 per service",
  },
  {
    icon: "images/charges_icon.svg",
    title: "RE-ORGANISE",
    description:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
    price: "KES 100 per service",
  },

  {
    icon: "images/charges_icon.svg",
    title: "CLEAN-UP",
    description:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
    price: "KES 100 per service",
  },
  {
    icon: "images/charges_icon.svg",
    title: "SCRIPTING",
    description:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
    price: "KES 100 per service",
  },
  {
    icon: "images/charges_icon.svg",
    title: "EDITING",
    description:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
    price: "KES 100 per service",
  },
  {
    icon: "images/charges_icon.svg",
    title: "RE-ORGANISE",
    description:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
    price: "KES 100 per service",
  },
];

export const subscriptionPlansData = [
  {
    icon: "images/subscription_image_first.svg",
    title: "Self-Service Dashboard",
    description: `We provide you with online dashboard login to manage all your media actions \n
    You get access to experts on-demand basis
    `,

    addFunctionCheckboxes: [
      { label: "CREATE & EDIT MEDIA" },
      { label: " STUDIO TOOLS" },
      { label: "  TRAINER TOOLS" },
      { label: "  DISTRIBUTE MEDIA" },
      { label: " MULTI-USER FUNCTIION" },
    ],

    chooseFeeCycleCheckboxes: [
      { label: "MONTHLY PLAN " },
      { label: " ANNUAL PLAN" },
    ],
    price: "22,500",
    payble: "Payable every month",
  },

  {
    icon: "images/subscription_image_second.svg",
    title: "Managed Dashboard",
    description: `We open an account for you and dedicate a team to action all your requests. We provide you with online dashboard login to provide your instructions to us
    `,

    addFunctionCheckboxes: [
      { label: "CREATE & EDIT MEDIA" },
      { label: " STUDIO TOOLS" },
      { label: "  TRAINER TOOLS" },
      { label: "  DISTRIBUTE MEDIA" },
      { label: " MULTI-USER FUNCTIION" },
    ],
    chooseFeeCycleCheckboxes: [
      { label: "MONTHLY PLAN " },
      { label: " ANNUAL PLAN" },
    ],

    price: "22,500",
    payble: "Payable every month",
  },

  {
    icon: "images/subscription_image_third.svg",
    title: "Premium Dashboard",
    description: `We provide you with dedicated USSD Dial Code in addition to either a self-service or managed dashboard
    `,

    radioButtons: [
      { label: "SELF - SERVICE", checked: true },
      { label: "MANAGED", checked: false },
    ],
    addFunctionCheckboxes: [
      { label: "CREATE & EDIT MEDIA" },
      { label: " STUDIO TOOLS" },
      { label: "  TRAINER TOOLS" },
      { label: "  DISTRIBUTE MEDIA" },
      { label: " MULTI-USER FUNCTIION" },
    ],

    chooseFeeCycleCheckboxes: [
      { label: "MONTHLY PLAN " },
      { label: " ANNUAL PLAN" },
    ],

    price: "22,500",
    payble: "Payable every month",
  },
];

export const jobDetailsData = [
  {
    title: "Service Type:",
    info: "Content Creator",
  },
  {
    title: "Description:",
    info: "creatives specialized in the development of audio and video content. They earn from taking content creation jobs from Fululizo, upon job orders places on Fululizo by content owners, partner organisations, brands and consumers",
  },
];

export const contractDetailsData = [
  {
    title: "Contract Fees :",
    info: "Content Creator",
  },
  {
    title: "Contract Duration:",
    info: "1 month",
    info2: " (after accepting the contract from both the parties.)",
  },
];

export const workViewData = [
  {
    title: "Service Provider:",
    info: "Arvind",
  },
  {
    title: "Service Type:",
    info: "Content Creator",
  },
  {
    title: "Payment Status:",
    info: "Paid",
  },
  {
    title: "Description:",
    info: "creatives specialized in the development of audio and video content. They earn from taking content creation jobs from Fululizo, upon job orders places on Fululizo by content owners, partner organisations, brands and consumers",
  },
  {
    title: "Order Status:",
    info: "Completed",
  },
  {
    title: "Remark :",
    info: "They earn from taking content creation jobs from Fululizo, upon job.",
  },
  {
    title: "File:",
    info: "Aina Za Umiliki Wa Biashara",
    image: <img src="images/imageMP4.png" alt="mp4" />,
  },
];

export const transactionPaymentData = [
  {
    title: "Transaction ID :",
    info: "TTCNI022000800594",
  },
  {
    title: "Transaction Amount :",
    info: "100 KES",
  },
  {
    title: "Transaction Date & Time :",
    info: "100 KES",
  },
];

export const orderTypeData = [
  {
    title: "Order Type :",
    info: "Podcast",
  },
  {
    title: "Media Name:",
    info: "Aina Za Umiliki Wa Biashara",
  },
  {
    title: "Media Price :",
    info: "100 KES",
  },
  {
    title: "User Name :",
    info: "Abhinav",
  },
];

export const orderTypeData2 = [
  {
    title: "Order Type :",
    info: "Podcast",
  },
  {
    title: "Media Name:",
    info: "Aina Za Umiliki Wa Biashara",
  },
  {
    title: "Media Price :",
    info: "100 KES",
  },
  {
    title: "User Name :",
    info: "Abhinav",
  },
  {
    title: "Service Provider Name:",
    info: "Abhinav",
  },
];
export const serviceOrderTypeData = [
  {
    title: "Order Type :",
    info: "Podcast",
  },
  {
    title: "Media Name:",
    info: "Aina Za Umiliki Wa Biashara",
  },
  {
    title: "Media Price :",
    info: "100 KES",
  },
  {
    title: "User Name :",
    info: "Abhinav",
  },
  {
    title: "Service Provider Name:",
    info: "Abhinav",
  },
];

export const jobListData = [
  {
    no: 1,
    userName: "Subashini",
    userType: "Consumer",
    serviceType: "CleanUp",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "In progress",
    action: "VIEW",
  },
  {
    no: 2,
    userName: "Subashini",
    userType: "Consumer",
    serviceType: "Content Writing",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Completed",
    action: "VIEW",
  },
  {
    no: 3,
    userName: "Subashini",
    userType: "Brand",
    serviceType: "Content Writing",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Accepted",
    action: "VIEW",
  },
  {
    no: 4,
    userName: "Subashini",
    userType: "Consumer",
    serviceType: "CleanUp",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "In progress",
    action: "VIEW",
  },
  {
    no: 5,
    userName: "Subashini",
    userType: "Brand",
    serviceType: "CleanUp",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Completed",
    action: "VIEW",
  },
  {
    no: 6,
    userName: "Subashini",
    userType: "Brand",
    serviceType: "CleanUp",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Pending",
    action: "VIEW",
  },
  {
    no: 7,
    userName: "Subashini",
    userType: "Consumer",
    serviceType: "CleanUp",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Completed",
    action: "VIEW",
  },
  {
    no: 8,
    userName: "Subashini",
    userType: "Consumer",
    serviceType: "CleanUp",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Completed",
    action: "VIEW",
  },
  {
    no: 9,
    userName: "Subashini",
    userType: "Consumer",
    serviceType: "CleanUp",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Completed",
    action: "VIEW",
  },
  {
    no: 10,
    userName: "Abhinav",
    userType: "Consumer",
    serviceType: "Content Writing",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Completed",
    action: "VIEW",
  },
];

export const projectOfferedData = [
  {
    no: 1,
    projectName: "Content Writing",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Pending",
    action: "VIEW",
  },
  {
    no: 2,
    projectName: "Video Editing",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "In Process",
    action: "VIEW",
  },
  {
    no: 3,
    projectName: "Content Writing",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Commented",
    action: "VIEW",
  },
  {
    no: 4,
    projectName: "Video Editing",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Pending",
    action: "VIEW",
  },
  {
    no: 5,
    projectName: "Content Writing",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Delivered",
    action: "VIEW",
  },
  {
    no: 6,
    projectName: "Video Editing",
    userType: "Brand",
    serviceType: "CleanUp",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Completed",
    action: "VIEW",
  },
  {
    no: 7,
    projectName: "Content Writing",

    date: "Jul 4, 2023,6:22:32 PM",
    status: "Pending",
    action: "VIEW",
  },
  {
    no: 8,
    projectName: "Video Editing",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Pending",
    action: "VIEW",
  },
  {
    no: 9,
    projectName: "Content Writing",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Pending",
    action: "VIEW",
  },
  {
    no: 10,
    projectName: "Video Editing",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Pending",
    action: "VIEW",
  },
];

export const viewJobDetailsData = [
  {
    title: "User Name :",
    info: "Arvind",
  },
  {
    title: "User Type :",
    info: "Consumer",
  },
  {
    title: "Service Type :",
    info: "Content Creator",
  },
  {
    title: "Description:",
    info: "creatives specialized in the development of audio and video content. They earn from taking content creation jobs from Fululizo, upon job orders places on Fululizo by content owners, partner organisations, brands and consumers",
  },
];

export const viewacceptedJobDetails = [
  {
    title: "User Name :",
    info: "Arvind",
  },
  {
    title: "Service Type :",
    info: "Content Creator",
  },
  {
    title: "Contract Price :",
    info: "100 Kenya Shiling",
  },
  {
    title: "Payment Status :",
    info: "Paid",
  },
  {
    title: "Description:",
    info: "creatives specialized in the development of audio and video content. They earn from taking content creation jobs from Fululizo, upon job orders places on Fululizo by content owners, partner organisations, brands and consumers",
  },
];

export const requestedJobDetailsData = [
  {
    title: "User Name :",
    info: "Arvind",
  },
  {
    title: "Service Type :",
    info: "Content Creator",
  },
  {
    title: "Contract Price :",
    info: "100 Kenya Shiling",
  },
  {
    title: "Payment Status :",
    info: "Paid",
  },

  {
    title: "Description:",
    info: "creatives specialized in the development of audio and video content. They earn from taking content creation jobs from Fululizo, upon job orders places on Fululizo by content owners, partner organisations, brands and consumers",
  },
];
export const contactData = [
  {
    image: <img src="images/contact_message.svg" alt="call" />,
    name: "connect@fululizo.com",
    info: "",
  },
  {
    image: <img src="images/contact_phone.svg" alt="mail" />,
    name: "Tel: +254 797 771771",
    info: "",
  },
  {
    image: <img src="images/contact_message.svg" alt="location" />,
    name: "INTEGRAL MEDIA LIMITED",
    info: "Jadala Place, 1st FloorNgong Lane, Off Ngong Road",
  },
];

export const privacyTextData = [
  {
    text: `These Terms and Conditions (“Terms”, “Terms and Conditions”) govern your relationship with Fululizo mobile applications and web panels (the “Service”) operated by Integral Media Limited (“us”, “we”, or “our”).<br></br>
      Please read these Terms and Conditions carefully before using our Fululizo mobile applications and web panels (the “Service”).<br></br>
      Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.<br></br>
      By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service
      Content.<br></br>
      Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material ('Content'). You are responsible for the Content that you post to the Service, including its legality, reliability, and appropriateness.<br></br>
      By posting Content to the Service, you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service. You retain any and all of your rights to any Content you submit, post or display on or through the Service and you are responsible for protecting those rights. You agree that this license includes the right for us to make your Content available to other users of the Service, who may also use your Content subject to these Terms.
      You represent and warrant that: (i) the Content is yours (you own it) or you have the right to use it and grant us the rights and license as provided in these Terms, and (ii) the posting of your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person Accounts<br></br>
      When you create an account with us, you must provide us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.
  You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service.<br></br>
  You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.<br></br>

   `,

    Availability: `
   We are constantly updating our offerings of products and services on the Service. The products or services available on our Service may be mispriced, described inaccurately, or unavailable, and we may experience delays in updating information on the Service and in our advertising on other web sites.
We cannot and do not guarantee the accuracy or completeness of any information, including prices, product images, specifications, availability, and services. We reserve the right to change or update information and to correct errors,inaccuracies, or omissions at any time without prior notice.<br></br>
   `,

    Purchases: `
   If you wish to purchase any product or service made available through the Service (“Purchase”), you may be asked to supply certain information relevant to your Purchase including, without limitation, your credit card number, the expiration date of your credit card,your billing address, and your shipping information.

You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete.By submitting such information, you grant us the right to provide the information to third parties for purposes of facilitating the completion of Purchases.

We reserve the right to refuse or cancel your order at any time for certain reasons including but not limited to: product or service availability, errors in the description or price of the product or service, error in your order or other reasons. We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is suspected.<br></br>
   `,
    Intellectual: `
   The Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of Integral Media Limited and its licensors. The Service is protected by copyright, trademark, and other laws of both the KENYA and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Integral Media Limited.
   `,

    OtherWebSites: `
   Our Service may contain links to third-party web sites or services that are not owned or controlled by Integral Media Limited.
Integral Media Limited has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party web sites or services. You further acknowledge and agree that Integral Media Limited shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.
We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.
   
   `,
    Termination: `
   We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.<br></br>
   Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.
   `,
    Limitation: `
   In no event shall Integral Media Limited, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.
   `,
    Disclaimer: `
   Your use of the Service is at your sole risk. The Service is provided on an “AS IS” and “AS AVAILABLE” basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.<br></br>
   Integral Media Limited its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.
   `,
    Law: `
   These Terms shall be governed and construed in accordance with the laws of KENYA, without regard to its conflict of law provisions.<br></br>
Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have between us regarding the Service.
   `,
    Changes: `
   We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days’ notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.<br><br/>
By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.
   `,
  },
];

export const createContractData = [
  {
    title: "Service Type :",
    info: "Content Creator",
  },
  {
    title: "Description :",
    info: "creatives specialized in the development of audio and video content. They earn from taking content creation jobs from Fululizo, upon job orders places on Fululizo by content owners, partner organisations, brands and consumers",
  },
];
export const filteredPodcast = [
  {
    postedDate: "Posted Date :- 02May,2023",
    artistName: "Aina Za Umiliki Wa Biashara",
    genre: "Mental heath & wellness",
    description:
      "Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.........",
    img: "images/card_image_first.png",
  },
  {
    postedDate: "Posted Date :- 02May,2023",
    artistName: "Aina Za Umiliki Wa Biashara",
    genre: "Mental heath & wellness",
    description:
      "Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.........",
    img: "images/card_image_second.png",
  },
  {
    postedDate: "Posted Date :- 02May,2023",
    artistName: "Aina Za Umiliki Wa Biashara",
    genre: "Mental heath & wellness",
    description:
      "Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.........",
    img: "images/card_image_third.png",
  },
  {
    postedDate: "Posted Date :- 02May,2023",
    artistName: "Aina Za Umiliki Wa Biashara",
    genre: "Mental heath & wellness",
    description:
      "Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.........",
    img: "images/card_image_first.png",
  },

  {
    postedDate: "Posted Date :- 02May,2023",
    artistName: "Aina Za Umiliki Wa Biashara",
    genre: "Mental heath & wellness",
    description:
      "Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.........",
    img: "images/card_image_first.png",
  },
  {
    postedDate: "Posted Date :- 02May,2023",
    artistName: "Aina Za Umiliki Wa Biashara",
    genre: "Mental heath & wellness",
    description:
      "Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.........",
    img: "images/card_image_second.png",
  },
  {
    postedDate: "Posted Date :- 02May,2023",
    artistName: "Aina Za Umiliki Wa Biashara",
    genre: "Mental heath & wellness",
    description:
      "Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.........",
    img: "images/card_image_third.png",
  },
  {
    postedDate: "Posted Date :- 02May,2023",
    artistName: "Aina Za Umiliki Wa Biashara",
    genre: "Mental heath & wellness",
    description:
      "Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.........",
    img: "images/card_image_first.png",
  },

  {
    postedDate: "Posted Date :- 02May,2023",
    artistName: "Aina Za Umiliki Wa Biashara",
    genre: "Mental heath & wellness",
    description:
      "Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.........",
    img: "images/card_image_first.png",
  },
  {
    postedDate: "Posted Date :- 02May,2023",
    artistName: "Aina Za Umiliki Wa Biashara",
    genre: "Mental heath & wellness",
    description:
      "Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.........",
    img: "images/card_image_second.png",
  },
  {
    postedDate: "Posted Date :- 02May,2023",
    artistName: "Aina Za Umiliki Wa Biashara",
    genre: "Mental heath & wellness",
    description:
      "Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.........",
    img: "images/card_image_third.png",
  },
  {
    postedDate: "Posted Date :- 02May,2023",
    artistName: "Aina Za Umiliki Wa Biashara",
    genre: "Mental heath & wellness",
    description:
      "Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.........",
    img: "images/card_image_first.png",
  },
];

export const commentsData = [
  {
    profileImage: "images/profile_image.svg",
    profileName: "Umair Siddiqui",
    comment:
      "No.3-Aina Za Umiliki Wa Biashara. Leo tunaye mtaalamu wa masuala ya biashara ambaye atatueleza aina za biashara -- na jinsi ya kujasili biashara yako. Jiunge na Mzee Majiko, kwenye safari hii muhimu itakayokuwezesha kuwa mfanyibiashara mashuhuri wa kutengeneza na kuuza majiko ya kisasa. Mfululizo huu wa ushauri wa biashara ya ICS umeletwa kwako na shirika la PRACTICAL.",
  },

  {
    profileImage: "images/profile_image.svg",
    profileName: "Umair Siddiqui",
    comment:
      "No.3-Aina Za Umiliki Wa Biashara. Leo tunaye mtaalamu wa masuala ya biashara ambaye atatueleza aina za biashara -- na jinsi ya kujasili biashara yako. Jiunge na Mzee Majiko, kwenye safari hii muhimu itakayokuwezesha kuwa mfanyibiashara mashuhuri wa kutengeneza na kuuza majiko ya kisasa. Mfululizo huu wa ushauri wa biashara ya ICS umeletwa kwako na shirika la PRACTICAL.",
  },

  {
    profileImage: "images/profile_image.svg",
    profileName: "Umair Siddiqui",
    comment:
      "No.3-Aina Za Umiliki Wa Biashara. Leo tunaye mtaalamu wa masuala ya biashara ambaye atatueleza aina za biashara -- na jinsi ya kujasili biashara yako. Jiunge na Mzee Majiko, kwenye safari hii muhimu itakayokuwezesha kuwa mfanyibiashara mashuhuri wa kutengeneza na kuuza majiko ya kisasa. Mfululizo huu wa ushauri wa biashara ya ICS umeletwa kwako na shirika la PRACTICAL.",
  },
];

export const relatedPostData = [
  {
    postedDate: "Posted Date :- 02May,2023",
    artistName: "Aina Za Umiliki Wa Biashara",
    genre: "Mental heath & wellness",
    description:
      "Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.........",
    img: "images/card_image_first.png",
  },
  {
    postedDate: "Posted Date :- 02May,2023",
    artistName: "Aina Za Umiliki Wa Biashara",
    genre: "Mental heath & wellness",
    description:
      "Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.........",
    img: "images/card_image_second.png",
  },
  {
    postedDate: "Posted Date :- 02May,2023",
    artistName: "Aina Za Umiliki Wa Biashara",
    genre: "Mental heath & wellness",
    description:
      "Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.........",
    img: "images/card_image_third.png",
  },
  {
    postedDate: "Posted Date :- 02May,2023",
    artistName: "Aina Za Umiliki Wa Biashara",
    genre: "Mental heath & wellness",
    description:
      "Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.........",
    img: "images/card_image_first.png",
  },
];

export const videoMedia = {
  videoTitle: "Aina Za Umiliki Wa Biashara",
  tags: [
    {
      tag: " Mental Health",
    },
    {
      tag: " NIBI Country",
    },
    {
      tag: " UENDESHAJI WA BIASHARA",
    },
  ],
  podcastPrice: "free",
  rating: 4.5,
  description: `No.3-Aina Za Umiliki Wa Biashara. Leo tunaye mtaalamu wa masuala
    ya biashara ambaye atatueleza aina za biashara -- na jinsi ya
    kujasili biashara yako. Jiunge na Mzee Majiko, kwenye safari hii
    muhimu itakayokuwezesha kuwa mfanyibiashara mashuhuri wa
    kutengeneza na kuuza majiko ya kisasa. Mfululizo huu wa ushauri wa
    biashara ya ICS umeletwa kwako na shirika la PRACTICAL ACTION.
    Hiki ni kipindi nambari tatu (3) katika vipindi ishirini (2)
    zilizo simuliwa kwa Kingereza na Kiswahili hapa FULULIZO CHANNEL.
    \n
    No.3-Aina Za Umiliki Wa Biashara. Leo tunaye mtaalamu wa masuala
    ya biashara ambaye atatueleza aina za biashara -- na jinsi ya
    kujasili biashara yako. Jiunge na Mzee Majiko, kwenye safari hii
    muhimu itakayokuwezesha kuwa mfanyibiashara mashuhuri wa
    kutengeneza na kuuza majiko ya kisasa. Mfululizo huu wa ushauri wa
    biashara ya ICS umeletwa kwako na shirika la PRACTICAL ACTION.
    Hiki ni kipindi nambari tatu (3) katika vipindi ishirini (2)
    zilizo simuliwa kwa Kingereza na Kiswahili hapa FULULIZO CHANNEL.
    \n
    No.3-Aina Za Umiliki Wa Biashara. Leo tunaye mtaalamu wa masuala
    ya biashara ambaye atatueleza aina za biashara -- na jinsi ya
    kujasili biashara yako. Jiunge na Mzee Majiko, kwenye safari hii
    muhimu itakayokuwezesha kuwa mfanyibiashara mashuhuri wa
    kutengeneza na kuuza majiko ya kisasa. Mfululizo huu wa ushauri wa
    biashara ya ICS umeletwa kwako na shirika la PRACTICAL ACTION.`,
};

export const audioMedia = {
  audioTitle: "Aina Za",
  tags: [
    {
      tag: "Mental heath & wellness",
    },
  ],
  images: [
    {
      leftImage: "images/left_audio_image.png",
      bottomImage: "images/bottom_audio_image1.png",
    },
  ],
  date: "August 9, 2021",
  audioDescription:
    "Dr. David Kiragu, a highly experienced obstetrician and gynaecologist, explains about an ectopic pregnancy, going into some level of",

  description: `No.3-Aina Za Umiliki Wa Biashara. Leo tunaye mtaalamu wa masuala
    ya biashara ambaye atatueleza aina za biashara -- na jinsi ya
    kujasili biashara yako. Jiunge na Mzee Majiko, kwenye safari hii
    muhimu itakayokuwezesha kuwa mfanyibiashara mashuhuri wa
    kutengeneza na kuuza majiko ya kisasa. Mfululizo huu wa ushauri wa
    biashara ya ICS umeletwa kwako na shirika la PRACTICAL ACTION.
    Hiki ni kipindi nambari tatu (3) katika vipindi ishirini (2)
    zilizo simuliwa kwa Kingereza na Kiswahili hapa FULULIZO CHANNEL.
    \n
    No.3-Aina Za Umiliki Wa Biashara. Leo tunaye mtaalamu wa masuala
    ya biashara ambaye atatueleza aina za biashara -- na jinsi ya
    kujasili biashara yako. Jiunge na Mzee Majiko, kwenye safari hii
    muhimu itakayokuwezesha kuwa mfanyibiashara mashuhuri wa
    kutengeneza na kuuza majiko ya kisasa. Mfululizo huu wa ushauri wa
    biashara ya ICS umeletwa kwako na shirika la PRACTICAL ACTION.
    Hiki ni kipindi nambari tatu (3) katika vipindi ishirini (2)
    zilizo simuliwa kwa Kingereza na Kiswahili hapa FULULIZO CHANNEL.
  `,
};

export const presentationMedia = {
  presentationTile: "Aina Za",
  presentationTags: "Mental heath & wellness",
  presentationDate: "August 9, 2021",
  presentationImage: "images/presentation_image.png",
  description:
    "No.3-Aina Za Umiliki Wa Biashara. Leo tunaye mtaalamu wa masuala ya biashara ambaye atatueleza aina za biashara -- na jinsi ya kujasili biashara yako. Jiunge na Mzee Majiko, kwenye safari hii muhimu itakayokuwezesha kuwa mfanyibiashara mashuhuri wa kutengeneza na kuuza majiko ya kisasa. Mfululizo huu wa ushauri wa biashara ya ICS umeletwa kwako na shirika la PRACTICAL ACTION. Hiki ni kipindi nambari tatu (3) katika vipindi ishirini (2) zilizo simuliwa kwa Kingereza na Kiswahili hapa FULULIZO CHANNEL.",

  slidesData: [
    {
      slideTitle: "Aina Za Umiliki Wa Biashara",
      slideTags: "Mental heath & wellness",
      slideDescription:
        "No.3-Aina Za Umiliki Wa Biashara. Leo tunaye mtaalamu wa masuala ya biashara ambaye atatueleza......",
      slideImage: "images/slid_image.png",
    },
    {
      slideTitle: "Aina Za Umiliki Wa Biashara",
      slideTags: "Mental heath & wellness",
      slideDescription:
        "No.3-Aina Za Umiliki Wa Biashara. Leo tunaye mtaalamu wa masuala ya biashara ambaye atatueleza......",
      slideImage: "images/slid_image.png",
    },
    {
      slideTitle: "Aina Za Umiliki Wa Biashara",
      slideTags: "Mental heath & wellness",
      slideDescription:
        "No.3-Aina Za Umiliki Wa Biashara. Leo tunaye mtaalamu wa masuala ya biashara ambaye atatueleza......",
      slideImage: "images/slid_image.png",
    },
    {
      slideTitle: "Aina Za Umiliki Wa Biashara",
      slideTags: "Mental heath & wellness",
      slideDescription:
        "No.3-Aina Za Umiliki Wa Biashara. Leo tunaye mtaalamu wa masuala ya biashara ambaye atatueleza......",
      slideImage: "images/slid_image.png",
    },
  ],
};

export const MediaList = [
  {
    Srno: 1,
    mediaName: "Aina Za Umiliki Wa Biashara",
    category: "NIBI Country",
    type: "Free",
    Price: "100",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Approved",
    action1: "VIEW",
    action2: "BLOCK",
    action3: "DELETE",
  },
  {
    Srno: 2,
    mediaName: "Aina Za Umiliki Wa Biashara",
    category: "NIBI Country",
    type: "Free",
    Price: "100",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Pending",
    action1: "VIEW",
    action2: "BLOCK",
    action3: "DELETE",
  },
  {
    Srno: 3,
    mediaName: "Aina Za Umiliki Wa Biashara",
    category: "NIBI Country",
    type: "Free",
    Price: "100",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Pending",
    action1: "VIEW",
    action2: "BLOCK",
    action3: "DELETE",
  },
  {
    Srno: 4,
    mediaName: "Aina Za Umiliki Wa Biashara",
    category: "NIBI Country",
    type: "Free",
    Price: "100",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Pending",
    action1: "VIEW",
    action2: "BLOCK",
    action3: "DELETE",
  },
  {
    Srno: 5,
    mediaName: "Aina Za Umiliki Wa Biashara",
    category: "NIBI Country",
    type: "Free",
    Price: "100",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Pending",
    action1: "VIEW",
    action2: "BLOCK",
    action3: "DELETE",
  },
  {
    Srno: 6,
    mediaName: "Aina Za Umiliki Wa Biashara",
    category: "NIBI Country",
    type: "Free",
    Price: "100",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Pending",
    action1: "VIEW",
    action2: "BLOCK",
    action3: "DELETE",
  },
];

export const FeedbackCount = [
  {
    Title: "Total Review",
    count: "10.8K",
    type: "Review",
    image: "images/starGroup.svg",
  },
  {
    Title: "Average Rating",
    count: "4.5",
    star: "1",
    type: "Rating",
    image: "images/rating.svg",
  },
  {
    Title: "Total Complete Job",
    count: "77",
    type: "Job",
    image: "images/job.svg",
  },
];

export const FeedbackRating = [
  {
    image: "images/profileImg.svg",
    name: "Amol Yadav",
    type: "Consumer",
    star: "4.5",
    date: "24-09-202",
    description:
      "creatives specialized in the development of audio and video content. They earn from taking content creation jobs from Fululizo, upon job orders places on Fululizo by content owners, partner organisations, brands and consumers.",
  },
  {
    image: "images/profileImg.svg",
    name: "Amol Yadav",
    type: "Consumer",
    star: "4.5",
    date: "24-09-202",
    description:
      "creatives specialized in the development of audio and video content. They earn from taking content creation jobs from Fululizo, upon job orders places on Fululizo by content owners, partner organisations, brands and consumers.",
  },
  {
    image: "images/profileImg.svg",
    name: "Amol Yadav",
    type: "Consumer",
    star: "4.5",
    date: "24-09-202",
    description:
      "creatives specialized in the development of audio and video content. They earn from taking content creation jobs from Fululizo, upon job orders places on Fululizo by content owners, partner organisations, brands and consumers.",
  },
];
export const AffiliatedMembersData = [
  {
    name: " Adrival Nerum",
    email: "abcd@mailinator.com",
    phno: "+91 8802642953",
    date: "13th March 2022, 15:09",
    lastlog: "13th March 2022, 15:09",
    status: "Active",
  },
  {
    name: " Adrival Nerum",
    email: "abcd@mailinator.com",
    phno: "+91 8802642953",
    date: "13th March 2022, 15:09",
    lastlog: "13th March 2022, 15:09",
    status: "Active",
  },
  {
    name: " Adrival Nerum",
    email: "abcd@mailinator.com",
    phno: "+91 8802642953",
    date: "13th March 2022, 15:09",
    lastlog: "13th March 2022, 15:09",
    status: "Active",
  },
  {
    name: " Adrival Nerum",
    email: "abcd@mailinator.com",
    phno: "+91 8802642953",
    date: "13th March 2022, 15:09",
    lastlog: "13th March 2022, 15:09",
    status: "Active",
  },
  {
    name: " Adrival Nerum",
    email: "abcd@mailinator.com",
    phno: "+91 8802642953",
    date: "13th March 2022, 15:09",
    lastlog: "13th March 2022, 15:09",
    status: "Active",
  },
  {
    name: " Adrival Nerum",
    email: "abcd@mailinator.com",
    phno: "+91 8802642953",
    date: "13th March 2022, 15:09",
    lastlog: "13th March 2022, 15:09",
    status: "Active",
  },
  {
    name: " Adrival Nerum",
    email: "abcd@mailinator.com",
    phno: "+91 8802642953",
    date: "13th March 2022, 15:09",
    lastlog: "13th March 2022, 15:09",
    status: "Active",
  },
];
export const RewardData = [
  {
    friendId: "Adrival Nerum",
    date: "24th July, 2023 13:09",
    commission: "Active",
  },
  {
    friendId: "Adrival Nerum",
    date: "24th July, 2023 13:09",
    commission: "Active",
  },
  {
    friendId: "Adrival Nerum",
    date: "24th July, 2023 13:09",
    commission: "Active",
  },
  {
    friendId: "Adrival Nerum",
    date: "24th July, 2023 13:09",
    commission: "Active",
  },
];
